import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    FormControlLabel,
    Switch,
    Avatar,
    SelectChangeEvent,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    Backdrop
} from '@mui/material';
import RELogo from '../../../../../Assets/AuthBackGround/RecEzyLogo.png';
import { theme } from '../../../../../theme';
import { useNavigate } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import APIBaseURL from '../../../../../AppConfig/AppConfig.json'
import axios from 'axios';
import CustomFields from '../CustomFields/CustomFields';
import ReactQuill from 'react-quill';


export interface IFieldData {
    fieldKey: number;
    fieldName: string;
    values: string[];
}

interface setJobNumProp{
    setJobNum:(jobNum:number)=>void;
    setMarketJobTitle:(jobTitle:string)=>void;
    setReadOnly:(value:boolean)=>void;
    runPostMarketJobData:(jobNum:number,jobTitle:string)=>void
    
}

const CreateJob: React.FC<setJobNumProp> = ({setJobNum,setMarketJobTitle,setReadOnly,runPostMarketJobData}) => {
    const navigate = useNavigate()
    const { tenant } = useParams<{ tenant: string }>();
    const baseURL = window.location.origin.endsWith('/')
        ? window.location.origin
        : `${window.location.origin}/`;
    const [textStyles, setTextStyles] = useState<React.CSSProperties>({
        fontFamily: 'Arial',
        listStyleType: 'none',
    });
    const location = useLocation();
    const { jobNum,ReadOnly } = location.state || {};
    const [jobSector, setJobSector] = useState('')
    const [jobTitle, setJobTitle] = useState('')
    const [jobDescription, setJobDescription] = useState('')
    const [errors, setErrors] = useState<{ [key: string]: string }>({})
    const [newFieldsData, setNewFieldsData] = useState<IFieldData[]>([]);
    const [showNewFieldContainer, setShowNewFieldContainer] = useState(
        !!newFieldsData.length,
    );
    const token = localStorage.getItem('access_token')
    const [internalJob, setInternalJob] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState({
        jobSector: '',
        jobTitle: '',
        jobStatus:'',
        jobDescription: '',
        internalJob: false,
        fields: [] as IFieldData[],  // Store initial custom fields data
    });
    const [jobStatus,setJobStatus]=useState<string>('')
    const [loading,setLoading]=useState(false)
    const [disableSaveJoblisting,setDisableSaveJoblisting]=useState(false)


    const modules = {

        toolbar: [
            [{ 'header': [] }],
            ['bold', 'italic', 'underline', 'link'],
            [{ 'list': 'bullet' }, { 'list': 'ordered' }],
        ],
        clipboard: {
            matchVisual: false, // Prevents Quill from auto-converting visual line breaks to `<p><br></p>`
          },
    };
    const handleJobListing = () => {
        navigate(`/${tenant}/Joblisting`)
    }

    useEffect(() => {
        if (!token) {
            navigate(`/${tenant}/SignIn`);
            return;
        }
        if (jobNum) {
            setJobNum(jobNum)
            setReadOnly(ReadOnly || false)
            const fetchJobData = async () => {
                try {
                    const response = await axios.get(`${APIBaseURL.apiBaseUrl}/job/jobdetails/${jobNum}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'X-Tenant': tenant,
                            'X-App': baseURL
                        }
                    });

                    if (response.status === 200) {
                        const jobData = response.data;
                        setMarketJobTitle(jobData.JobTitle)
                        setJobTitle(jobData.JobTitle);
                        setJobSector(jobData.Department);
                        setJobDescription(jobData.JobDescr);
                        setInternalJob(jobData.InternalJob)
                        setJobStatus(jobData.Status)

                        setInitialValues({
                            jobSector: jobData.Department || '',
                            jobTitle: jobData.JobTitle || '',
                            jobStatus: jobData.Status || '',
                            jobDescription: jobData.JobDescr || '',
                            internalJob: jobData.InternalJob || false,
                            fields: jobData.fields || [],
                        });
                        // Initialize other fields if required
                        const fieldResponse = await axios.get(`${APIBaseURL.apiBaseUrl}/job/get_jobfield/${jobNum}`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'X-Tenant': tenant,
                                'X-App': baseURL
                            }
                        })
                        if (fieldResponse.status === 200) {
                            const fetchedFields = fieldResponse.data.map((field: any, index: number) => ({
                                fieldKey: index + 1,
                                fieldName: field.FieldName,
                                values: field.FieldValue ? field.FieldValue.split(',') : [],
                            }));
                            setNewFieldsData(fetchedFields);
                            setShowNewFieldContainer(!!fetchedFields.length)
                            setInitialValues((prev) => ({ ...prev, fields: fetchedFields }));
                        }
                    }
                } catch (error) {
                    console.error('Error fetching job data:', error);
                }
            };
            fetchJobData();
        }
    }, [jobNum]);


    const hasChanges = () => {
        // If no JobNum, assume it's a new job creation and always return true for non-empty fields
        if (!jobNum) {
            return true
        }
        // Check if main fields have changed
        if (
            initialValues.jobSector !== jobSector ||
            initialValues.jobTitle !== jobTitle ||
            initialValues.jobDescription !== jobDescription ||
            initialValues.internalJob !== internalJob||
            initialValues.jobStatus!==jobStatus
        ) {
            return true;
        }

        // Check if custom fields have changed
        if (initialValues.fields.length !== newFieldsData.length) return true;
        for (let i = 0; i < newFieldsData.length; i++) {
            if (
                initialValues.fields[i].fieldName !== newFieldsData[i].fieldName ||
                JSON.stringify(initialValues.fields[i].values) !== JSON.stringify(newFieldsData[i].values)
            ) {
                return true;
            }
        }

        return false;
    };

   
    const handleSaveJob = async () => {
        if (!hasChanges()) {
            navigate(`/${tenant}/Joblisting`)
            return;
        }
        const newErrors: { [key: string]: string } = {};
        if (jobSector.trim().length === 0) {
            newErrors.jobsector = 'Please fill function field.'
        }
        if (jobTitle.trim().length === 0) {
            newErrors.jobtitle = 'Please fill job title field.'
        }
        if (jobDescription.trim().length === 0) {
            newErrors.jobdescription = 'Please fill job description field.'
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return
        }

        if (!token) {
            navigate(`/${tenant}/SignIn`)
            return
        }
        else {
            setLoading(true)
            try {
                const apiUrl = jobNum
                    ? `${APIBaseURL.apiBaseUrl}/job/edit_job`  // Update if editing
                    : `${APIBaseURL.apiBaseUrl}/job/add_job`;  // Add if creating 

                const response = await axios.post(apiUrl, {
                    "JobTitle": jobTitle, "Department": jobSector, "JobStatus":jobStatus,"JobDescr": jobDescription, "Fields": newFieldsData, "JobNum": jobNum, "InternalJob": internalJob
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'X-Tenant': tenant,
                        'X-App': baseURL
                    }
                })
                if (response.status === 200 || response.status === 201) {
                    // Only call runPostMarketJobData if the job was created, not edited
                    if (!jobNum) {
                        runPostMarketJobData(response.data.JobNum, jobTitle);
                    }
                    setLoading(false)
                    navigate(`/${tenant}/Joblisting`)
                }

            } catch (error) {
                setLoading(false)
                newErrors.error = 'Please try again.'
            }
            setErrors(newErrors)
        }

    }

    

    const handleAddMoreField = (e: React.FormEvent) => {
        if (!showNewFieldContainer) setShowNewFieldContainer(true);
        const initFieldData: IFieldData = {
            fieldKey: newFieldsData.length + 1,
            fieldName: '',
            values: [],
        };
        setNewFieldsData([...newFieldsData, initFieldData]);
    };

    const onFieldDataUpdate = (newData: IFieldData) => {
        const updatedFieldsData = newFieldsData.map((data) =>
            data.fieldKey === newData.fieldKey ? newData : data,
        );
        setNewFieldsData(updatedFieldsData);
    };

    const onDeleteField = (deleteKey: number) => {
        const updatedFieldsData = newFieldsData.filter(
            (data) => data.fieldKey !== deleteKey,
        );
        setNewFieldsData(updatedFieldsData);
    };

    

    if(ReadOnly){
        return(
            <Box mb={7} sx={{ '& .MuiBox-root': { pb: 0, borderRadius: '1.11vw' } }}>
                <Typography variant="h5">Job Details (Read Only)</Typography>
                <Box mt={2} p={3} border={1} borderRadius={4} borderColor="divider">
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Typography variant="body1"><strong>Function: </strong>{jobSector}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1"><strong>Job Title: </strong>{jobTitle}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1"><strong>Status: </strong>{jobStatus}</Typography>
                        </Grid>
                    </Grid>

                    {/* Job Description */}
                    <Box my={2}>
                        <Typography variant="subtitle1">Description:</Typography>
                        <Box
                            sx={{
                                border: 1,
                                borderColor: 'divider',
                                borderRadius: 2,
                                p: 2,
                                backgroundColor: 'background.paper',
                            }}
                            dangerouslySetInnerHTML={{ __html: jobDescription }}
                        />
                    </Box>

                    {newFieldsData.length > 0 && (
                        <>
                            {/* Display Custom Fields heading */}
                            <Typography variant="subtitle1" mt={2} mb={1}>
                                Custom Fields:
                            </Typography>

                            {/* Add 1 tab space and display newFieldsData */}
                            <Grid container spacing={1} alignItems="center" mb={1.5} sx={{ pl: 2 }}>
                                {newFieldsData.map((field) => (
                                    <Grid item xs={12} key={field.fieldKey}>
                                        <Typography variant="body1">
                                            <strong>{field.fieldName}: </strong>
                                            {field.values.join(', ')}
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                </Box>
            </Box>
        )
    }
    return (
        <Box mb={7} sx={{ '& .MuiBox-root': { pb: 0, borderRadius: '1.11vw' } }} >
            <Typography variant="h5" gutterBottom sx={{ marginBottom: 0, ...textStyles }}>
                {jobNum ? 'Edit Job Posting' : 'Create a Job Posting'}
            </Typography>
            <Typography variant="subtitle1" sx={textStyles}>
                {jobNum ? 'Update your job posting details' : 'Design the ideal job posting to fill your open role'}
            </Typography>
            <Box mt={2} p={3} border={1} borderRadius={4} borderColor="divider">
                <Grid container spacing={2}>
                    <Grid item xs={jobNum ? 4 : 6}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Function *"
                            value={jobSector}
                            onChange={(e) => { setJobSector(e.target.value) }}
                            error={!!errors.jobsector}
                            helperText={errors.jobsector}
                            autoComplete='off' />
                    </Grid>
                    <Grid item xs={jobNum ? 4 : 6}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Job Title *"
                            value={jobTitle}
                            onChange={(e) => { setJobTitle(e.target.value) }}
                            error={!!errors.jobtitle}
                            helperText={errors.jobtitle}
                            autoComplete='off' />
                    </Grid>
                    {jobNum ? (
                        <Grid item xs={jobNum ? 4 : 6}>
                            <FormControl fullWidth size="small" required>
                                <InputLabel>Job Status</InputLabel>
                                <Select value={jobStatus} label="Job Status" onChange={(e) => setJobStatus(e.target.value)} >
                                    <MenuItem value="Open">Open</MenuItem>
                                    <MenuItem value="Close">Close</MenuItem>
                                    <MenuItem value="On-hold">On-hold</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    ) : (<></>)}
                </Grid>
                
                {/* Replace the current TextField with ReactQuill for Description */}
                <Box my={2}>
                    <Typography variant="subtitle1" sx={{ ...textStyles, mb: 1 }}>
                        Description *
                    </Typography>
                    <ReactQuill
                        value={jobDescription}
                        onChange={setJobDescription}
                        modules={modules}
                        style={{ height: '12vw', marginBottom: '4vh' }} // Set your desired height here
                    />
                    {errors.jobdescription&&<Typography variant='body1'  sx={{color:'red',margin:'1vw',position:'relative',top:'2vh'}}>{errors.jobdescription}</Typography>}
                </Box>

                <Grid container spacing={1} alignItems="center" mt={0.5}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                bgcolor: 'white',
                                alignItems: 'center',
                                padding: '0.15vw', 
                                margin: '1.1vw 0', 
                                overflow: 'hidden',
                                // borderRadius: '0.56vw',
                            }}
                            onDrop={(e) => {
                                e.preventDefault();
                                // Handle file drop here
                            }}
                            onDragOver={(e) => e.preventDefault()}
                        >
                            <Avatar src={RELogo} sx={{ marginRight: '1.7vw', top: '-2.4vw',width:'3vw',height:'3vw' }} /> {/* Converted to vw */}
                            <Box component="label" sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                // height: '100%',
                                padding: 0,
                                cursor: 'pointer',
                            }}>
                                <Button variant="outlined" component="label" sx={{
                                    flexDirection: 'column',
                                    bgcolor: 'inherit',
                                    // height: '100%',
                                    padding: '1.38vw 0', // Converted to vw
                                    marginBottom: '0.7vw', // Converted to vw
                                    boxShadow: 'none',
                                    borderRadius: '0.55vw', // Converted to vw
                                    border: '0.069vw solid ',
                                    borderColor: theme.palette.custom.border,
                                }}>
                                    <Grid
                                        sx={{
                                            width: '2.8vw', // Converted to vw
                                            height: '4.48vh', // Converted to vw
                                            border: '0.069vw solid #E4E7EC',
                                            marginBottom: '0.55vw', // Converted to vw
                                            borderRadius: '0.7vw', // Converted to vw // Ensure the Box has the borderRadius
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >

                                        <i
                                            style={{
                                                color: theme.palette.primary.main,
                                            }}
                                            className="fa-solid fa-cloud-arrow-up fa-xl"
                                        />
                                    </Grid>
                                    <Box>
                                        <Typography variant="body1" sx={{ display: 'inline', color: '#7F56D9' }}>
                                            Upload Logo
                                        </Typography>
                                        <Typography variant="body2" sx={{ display: 'inline', color: '#565656' }}>
                                            {'  '}or drag and drop
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" sx={{ color: '#565656' }}>
                                        SVG, PNG, JPG or GIF (max. 800x400px)
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
           
            </Box>
            <Box>
            <Grid container spacing={2} pb={1.5} alignItems="center" sx={{ backgroundColor: theme.palette.primary.light, borderBottomLeftRadius: '1vw', borderBottomRightRadius: '1vw',pl:'0.56vw',width:'100%',position:'relative',left:'1.1vw' }}>
                    <Grid item xs={5.8} p="0 0.35vw 0 0.2vw">
                        <FormControlLabel
                            control={<Switch
                                checked={internalJob} // This will bind the switch to internalJob state
                                onChange={() => setInternalJob((prev) => !prev)} // Toggle the internalJob state
                                sx={{
                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                        color: '#7F56D9',
                                        '&:hover': {
                                            backgroundColor: '#7F56D9',
                                        },
                                    },
                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                        backgroundColor: '#7F56D9',
                                    },
                                }} />}
                            label="Internal Job Posting"
                        />
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Button variant="outlined" sx={{ marginRight: '0.83vw' }} onClick={handleJobListing}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={handleSaveJob} sx={{ marginRight: '0.2vw' }} disabled={disableSaveJoblisting} >
                            Save Job Listing
                        </Button>
                    </Grid>
                </Grid>
                {/* Circular Progress with Transparent Background */}
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column', // Stack the CircularProgress and Typography
                    }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                    <Typography sx={{ marginTop: 2 }}>Creating new job post...</Typography>
                </Backdrop>

            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '1.25vw' }}>
                <Typography variant="h6">Custom Fields</Typography>
                <Button
                    variant="outlined"
                    sx={{ color: "#7F56D9", position: 'relative', right: '-1.042vw' }}
                    onClick={handleAddMoreField}
                >
                    Add Custom Fields
                </Button>
            </Box>
            {showNewFieldContainer && newFieldsData.length > 0 && (
                <TableContainer component={Paper} >
                    <Table>
                        <TableHead >
                            <TableRow  >
                                <TableCell sx={{ padding: '1.11vw' }}>
                                    Fields
                                </TableCell >
                                <TableCell sx={{ padding: '1.11vw' }}>
                                    Values
                                </TableCell>
                                <TableCell sx={{ padding: '1.11vw 0vw 1.11vw 2.78vw', textAlign: 'center' }}>
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {newFieldsData.map((data) => (
                                <CustomFields
                                    key={data.fieldKey}
                                    fieldKey={data.fieldKey}
                                    fieldData={data}
                                    onFieldDataUpdate={onFieldDataUpdate}
                                    onDeleteField={onDeleteField}
                                    existingFields={newFieldsData.map((field) => field.fieldName)} // Pass all field names as existingFields
                                    setDisableSaveJoblisting={setDisableSaveJoblisting}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export default CreateJob;
