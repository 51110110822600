import React, { useState, forwardRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Box,
  Slide,
  IconButton,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { SelectChangeEvent } from '@mui/material/Select'; // Import SelectChangeEvent type
import { theme } from '../../../../../theme';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface FilterSortDialogProps {
  open: boolean;
  onClose: () => void;
  onApply: (filters: any) => void;
}

const sortingOptions = [
  { value: 'CreatedDate', label: 'Date Created' },
];

const dropdownOptions = [
  { value: 'JobTitle', label: 'Job Title' },
  { value: 'Function', label: 'Function' },
  { value: 'CreatedBy', label: 'Created By' },
];

const jobStatusOptions = [
  { value: 'Open', label: 'Open' },
  { value: 'Close', label: 'Close' },
  { value: 'On-hold', label: 'On-hold' },
];

const FilterSortDialog: React.FC<FilterSortDialogProps> = ({ open, onClose, onApply }) => {
  const [sortBy, setSortBy] = useState<string>('');
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [filterText, setFilterText] = useState<{ [key: string]: string }>({});
  const [jobStatus, setJobStatus] = useState<string>('');

  // Update event type to SelectChangeEvent
  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    if (!selectedFilters.includes(selectedValue)) {
      setSelectedFilters([...selectedFilters, selectedValue]);
    }
  };

  const handleRemoveFilter = (filter: string) => {
    setSelectedFilters(selectedFilters.filter((item) => item !== filter));
    const newFilterText = { ...filterText };
    delete newFilterText[filter];
    setFilterText(newFilterText);
  };

  const handleTextFieldChange = (filter: string, value: string) => {
    setFilterText({ ...filterText, [filter]: value });
  };

  const handleApplyFilters = () => {
    console.log(filterText,jobStatus,sortBy)
    onApply({
      sortBy,
      selectedFilters,
      filterText,
      jobStatus,
    });
    onClose();
  };

  const handleClearFilters = () => {
    setSortBy('');
    setSelectedFilters([]);
    setFilterText({});
    setJobStatus('');
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      fullScreen
      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      PaperProps={{
        sx: {
          width: '20vw',
          height: '100vh',
          margin: 0,
          overflow: 'hidden',
          position: 'absolute',
          right: 0,
        },
      }}
    >
      <DialogTitle sx={{ display: 'flex', p: '2vw 1.5vw 0vw 1vw' }}>
        <Box
          color={theme.palette.secondary.main}
          sx={{
            border: '0.56vw solid #EEEEEE',
            bgcolor: theme.palette.secondary.light,
            margin: '0.6vw',
            mr: '1.6vw',
            width: '3.4vw',
            height: '3.4vw',
            borderRadius: '1vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <i className="fa-solid fa-sliders "></i>
        </Box>
        <Box>
          <Typography variant="h6" component="span" sx={{ display: 'table-row' }}>Filter & Sort</Typography>
          <Typography variant="body3">
            Filters to get the best results
          </Typography>
        </Box>
      </DialogTitle>

      <Divider sx={{ mb: '1.2vh', mt: '2vh' }} />

      <DialogContent sx={{ overflowY: 'auto', pt: 0, mt: '1vh' }}>
        <DialogActions sx={{ justifyContent: 'space-between', p: 0, height: '2.4vh' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button onClick={handleClearFilters} color="secondary" sx={{ ml: 'auto', position: 'relative', right: '-1vw' }}>
              Clear
            </Button>
          </Box>
        </DialogActions>

        {/* Dropdown for Job Title, Function, Job Status, Created By, Updated By */}
        <Typography variant='subtitle1' sx={{ mt: '1.5vh' }}>Filter By</Typography>
        <FormControl fullWidth margin="dense">
          <InputLabel size="small" id="filter-label">Select</InputLabel>
          <Select
            labelId="filter-label"
            label="Select"
            value=""
            size="small"
            onChange={handleFilterChange}
          >
            {dropdownOptions.map((option) => (
              !selectedFilters.includes(option.value) && (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              )
            ))}
          </Select>
        </FormControl>

        {/* Dynamically render text fields for selected filters */}
        {selectedFilters.map((filter) => (
          <Box key={filter} sx={{ mt: '1.5vh',display:'flex' }}>
            <TextField
              label={`Enter ${filter}`}
              variant="outlined"
              size="small"
              fullWidth
              margin="dense"
              value={filterText[filter] || ''}
              onChange={(e) => handleTextFieldChange(filter, e.target.value)}
            />
            <IconButton
              color="error"
              onClick={() => handleRemoveFilter(filter)}
              sx={{ mt: '0.5vh' }}
            >
              <i className="fa-solid fa-xmark"></i>
            </IconButton>
          </Box>
        ))}

        {/* Job Status Dropdown */}
        <Typography variant='subtitle1' sx={{ mt: '1.5vh' }}>Job Status</Typography>
        <FormControl fullWidth margin="dense">
          <InputLabel size="small" id="job-status-label">Select</InputLabel>
          <Select
            labelId="job-status-label"
            label="Select"
            value={jobStatus}
            size="small"
            onChange={(e) => setJobStatus(e.target.value as string)}
          >
            {jobStatusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Sorting Options */}
        <Typography variant='subtitle1' sx={{ mt: '1.5vh' }}>Sort By</Typography>
        <FormControl fullWidth margin="dense">
          <InputLabel size="small" id="sort-label">Select</InputLabel>
          <Select
            labelId="sort-label"
            label="Select"
            value={sortBy}
            size="small"
            onChange={(e) => setSortBy(e.target.value as string)}
          >
            {sortingOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <Divider />
      <DialogActions>
        <Box sx={{ display: 'flex', gap: '2vw', width: '100%', p: '0vw 1.6vw' }}>
          <Button onClick={onClose} variant="outlined" fullWidth>
            Cancel
          </Button>
          <Button onClick={handleApplyFilters} variant="contained" fullWidth>
            Apply
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FilterSortDialog;
