import React, { useState, forwardRef, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  Typography,
  Box,
  IconButton,
  Slide,
  Chip,
  TextField,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from '../../../../theme';
import { ScreeningStatus } from '../CandidateContent/CandidateContent.styles';
import { AssignmentStatus } from '../CandidateContent/CandidateContent.styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Slide transition component
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// Updated interface
interface NotesPopupProps {
  open: boolean;
  onClose: () => void;
  userStatus: string | null; // Accepting userStatus as a prop
  candidateNotes: { MapID: number, NoteValue: string; RecruiterName: string }[];
  handleSave: (candidateNum: number | null, noteValue: string | null) => void
  candidateMapId: number | null;
  candidateName: string | null
}



const NotesPopup: React.FC<NotesPopupProps> = ({ open, onClose, candidateMapId, userStatus, candidateNotes, handleSave, candidateName }) => {
  const [newNote, setNewNote] = useState<string>('');
  const [notes, setNotes] = useState<{ MapID: number, NoteValue: string; RecruiterName: string }[]>([]); // List of notes with status


  const handleNoteChange = (value: string) => {
    setNewNote(value); // Update new note text
  };

  useEffect(() => {
    setNotes(candidateNotes || [])
  }, [candidateNotes])




  const handleSaveNote = () => {
    handleSave(candidateMapId, newNote)
    setNewNote('')
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      fullScreen
      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      PaperProps={{
        sx: {
          maxWidth: '20vw', // Responsive width
          height: '100vh',
          margin: 0,
          overflow: 'hidden',
          position: 'absolute',
          right: 0,
        },
      }}
    >
      {/* Header */}
      <DialogTitle sx={{ display: 'flex', p: '2vh 1.5vw 0 1vw', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex' }}>
          <Box
            color={theme.palette.secondary.main}
            sx={{
              border: '0.07vw solid #EEEEEE',
              bgcolor: theme.palette.secondary.light,
              margin: '0.4vh',
              mr: '1vw',
              width: '2.4vw',
              height: '5vh',
              borderRadius: '0.6vw',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <i className="fa-regular fa-bell"></i>
          </Box>
          <Box >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', gap: '9vw', alignItems: 'center' }}>
                <Typography variant="h6">Notes</Typography>
                <IconButton
                  edge="end"
                  color="inherit"
                  sx={{ position: 'relative', right: '-1vw', top: '-1vh', width: '2vw', height: '4vh' }}
                  onClick={onClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Typography variant="body3" sx={{ marginBottom: '0.8vh' }}>Notes during the interview process</Typography>
            </Box>
          </Box>

        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5vw' }}>
          <Typography variant="body3"  >{candidateName}</Typography>
          <Typography variant='body3'>{userStatus === 'Screening' ? (
            <ScreeningStatus>• {userStatus}</ScreeningStatus>
          ) : (
            <AssignmentStatus>• {userStatus}</AssignmentStatus>
          )}</Typography>

        </Box>

      </DialogTitle>

      <Divider sx={{ mt: 2 }} />

      {/* Content Area */}
      {/* Content Area */}
      <DialogContent sx={{ overflowY: 'auto', pt: 1 }}>
        {notes.map((note, index) => (
          <Box my={2} key={index}>
            <Typography variant="subtitle1" display="flex" alignItems="center">
              {note.RecruiterName}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                whiteSpace: 'pre-wrap', // Allow text to wrap
                wordWrap: 'break-word', // Break long words
                overflow: 'hidden' // Prevent overflow
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: note.NoteValue }} />
            </Typography>
            <Divider sx={{ my: 2.5 }} />
          </Box>
        ))}
      </DialogContent>


      {/* Add Note Section */}
      <DialogActions sx={{ padding: '1vh 1vw', gap: 4, display: 'flex', flexDirection: 'column' }}>
        <ReactQuill
          value={newNote}
          onChange={handleNoteChange}
          style={{ height: '20vh', width: '100%' }}
          modules={{
            toolbar: [
              ['bold', 'italic', 'link'],
              [{ list: 'bullet' }, { list: 'ordered' }],
            ],
            clipboard: {
              matchVisual: false, // Prevents Quill from auto-converting visual line breaks to `<p><br></p>`
            },
          }}
        />
        <Button
          onClick={handleSaveNote}
          variant="contained"
          sx={{
            width: '100%',
            height: '2vw',
            minWidth: '2vw',
            minHeight: '2vw',
            marginTop: '2vh',
            gap: 1,
          }}
        >
          <i className="fa-solid fa-plus"></i>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotesPopup;
