import React, { useState, useEffect } from 'react';
import {
  TableCell, TableRow, IconButton, TextField
} from '@mui/material';
import {Edit, Delete } from '@mui/icons-material';
import { IFieldData } from '../CreateJob/CreateJob';



interface ICreateNewFieldProps {
  fieldKey: number;
  onFieldDataUpdate: (a: IFieldData) => void;
  onDeleteField: (a: number) => void;
  fieldData:IFieldData;
  existingFields: string[]; // List of existing field names;
  setDisableSaveJoblisting:(value:boolean)=>void
}

const CustomFields: React.FC<ICreateNewFieldProps> = (props: ICreateNewFieldProps) => {
  
  const { fieldKey, onFieldDataUpdate, onDeleteField,existingFields,setDisableSaveJoblisting  } = props;
  const [fieldName, setFieldName] = useState(props.fieldData.fieldName || '');
  const [values, setValues] = useState<string[]>(props.fieldData.values||[]);
  const [error, setError] = useState<string>(''); // State to hold the error message

 

  useEffect(() => {
    if (!error) {
      onFieldDataUpdate({
        fieldKey,
        fieldName: fieldName,
        values: values,
      });
    }
  }, [fieldName, values, fieldKey, error, onFieldDataUpdate]);

  const handleFieldNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.trim();

    // Exclude the current field name from the validation check
  const otherFields = existingFields.filter((name) => name.toLowerCase() !== fieldName.toLowerCase());

  // Check for case-insensitive duplicates
  if (otherFields.map((name) => name.toLowerCase()).includes(newValue.toLowerCase())) {
    setError('Field name already exists. Please choose a different name.');
    setDisableSaveJoblisting(true)
  } else {
    setDisableSaveJoblisting(false)
    setError('');
    setFieldName(newValue);
  }
  };

  // const handleFieldNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setFieldName(e.target.value);
  // };

  const handleOnDeleteField = () => {
    onDeleteField(fieldKey);
  };

 
  return (
    <TableRow
    sx={{
      backgroundColor: fieldKey % 2 === 1 ? '#F9F5FF' : 'transparent'
    }}
    >
      <TableCell sx={{ padding: '1.111vw' }}>
          <TextField
            size="small"
            variant="outlined"
            value={fieldName}
            onChange={handleFieldNameChange}
            placeholder="Enter field name"
            fullWidth
            autoComplete='off'
            error={!!error} // Show red border if there's an error
            helperText={error} // Display error message
          />
        </TableCell>
        <TableCell sx={{ padding: '1.111vw' }}>
        <TextField
              size="small" 
              variant="outlined"
              placeholder="Enter values separated by comma"
              value={values}
              onChange={(e) => {
                setValues(e.target.value.split(','))
              }}
              fullWidth
              autoComplete='off'
          />
        </TableCell>
        <TableCell sx={{
              padding: '1.11vw',
              display: 'flex',
              ml:'2vw',
              justifyContent: 'center',
              gap: '0.069vw', // Convert to vw
            }}>
        <IconButton sx={{ color: "#7F56D9" }} >
              <Edit />
            </IconButton>
            <IconButton sx={{ color: "#7F56D9" }} onClick={handleOnDeleteField}>
              <Delete></Delete>
            </IconButton>
        </TableCell>
    </TableRow>
  );
};

export default CustomFields;
