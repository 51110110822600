import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip } from '@mui/material';
import { TableWrapper, TableRowStyled } from '../../JobListing/JobListing.styles'; // Imported styles from JobListing
import { ArrowDropDown } from '@mui/icons-material';
import companylogo1 from "../../../../../Assets/AuthBackGround/c1_logo.png";
import Pagination from '../../../../Common/Pagination/pagination';


interface similarJobsDataType{
  CompanyName:string|null,
  JobTitle:string|null,
  Region:string|null,
  SimilarJobNum:number|null,
  JobLink:string
}

interface SimilarJobdProp{
  similarJobsData:similarJobsDataType[]|[],
}
const sampleSimilarJobs = Array.from({ length: 30 }, (_, index) => ({
  id: index + 1,
  company: `Company ${index + 1}`,
  region: 'Region',
  skills: ['Project Management', 'Communication', 'Negotiation', 'Leadership', '+5'],
  benefits: ['Leave', 'Health', 'Dental', 'Retirement', 'Insurance', '+4'],
}));

const SimilarJobPostings: React.FC<SimilarJobdProp> = ({similarJobsData}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10); // Default rows per page


  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as number;
    setRowsPerPage(value);
    setPage(0);
  };

  const totalPages = Math.ceil(similarJobsData.length / rowsPerPage);

  const renderChipsWithLimit = (items: string[]) => {
    const colors = [
      { text: '#6941C6', background: '#F9F5FF', border: '0.07vw solid #E9D7FE' },
      { text: '#175CD3', background: '#EFF8FF', border: '0.07vw solid #B2DDFF' },
      { text: '#3538CD', background: '#EEF4FF', border: '0.07vw solid #C7D7FE' },
    ];

    const maxItems = 3;
    const displayItems = items.slice(0, maxItems);
    const extraItems = items.length > maxItems ? items.length - maxItems : 0;

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'nowrap',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '66.81vw', 
          margin: '0vw',
        }}
      >
        {displayItems.map((item, index) => (
          <Chip
            key={item}
            label={item}
            sx={{
              height: '1.53vw',
              borderRadius: '2.77vw',
              fontSize: '0.83vw',
              color: colors[index % 3].text,
              backgroundColor: colors[index % 3].background,
              border: colors[index % 3].border,
              marginRight: '0.27vw',
            }}
          />
        ))}
        {extraItems > 0 && (
          <Chip
            label={`+${extraItems}`}
            sx={{
              height: '1.53vw',
              borderRadius: '2.77vw',
              fontSize: '0.83vw',
              backgroundColor: '#E4E7EC',
              border: '0.07vw solid #000000',
              marginRight: '0.27vw',
            }}
          />
        )}
      </Box>
    );
  };

  return (
    <Box
      mt="2.78vw"
      sx={{
        width: '100%',
        margin: '0vw',
        mb: '1.39vw',
        boxSizing: 'border-box',
      }}
    >
      <Typography variant="h5">Look up similar job postings in your region</Typography>
      <Typography variant="subtitle2" gutterBottom>
        The system will provide you with a list of similar open positions and their details
      </Typography>

      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '15.56vw', padding: '0.15vw 0.69vw' }}><Typography variant='body3' >Open Positions</Typography></TableCell>
              <TableCell sx={{ width: '8.33vw', padding: '0.15vw 0.69vw' }}><Typography variant='body3' >Company</Typography></TableCell>
              <TableCell sx={{ width: '8.33vw', padding: '0.15vw 0.69vw' }}><Typography variant='body3' >Region</Typography></TableCell>
              {/* <TableCell sx={{ padding: '0.15vw 0.69vw' }}><Typography variant='body3' >Skills</Typography></TableCell> */}
              {/* <TableCell sx={{ padding: '0.15vw 0.69vw' }}><Typography variant='body3' >Benefits</Typography></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {similarJobsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).filter((job)=>job.JobTitle!=='Not Available').map((row, index) => (
              <TableRowStyled key={row.SimilarJobNum} iseven={(index % 2 === 0).toString()}>
                <TableCell sx={{ display: 'flex', alignItems: 'center', padding: '0vw 0.69vw', height: '5vw' }}>
                  {/* <img src={companylogo1} alt="Company Logo" style={{ width: '2.64vw', height: '2.64vw', marginRight: '0.56vw' }} /> */}
                  <Box>
                    <Typography variant="body2" >{row.JobTitle}</Typography>
                    <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                      {row.JobLink?(<a href={row.JobLink} target="_blank" rel="noopener noreferrer">
                        Click here to see the Job
                      </a>):(
                        <></>
                      )}
                      
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ padding: '0vw 0.69vw' }}>{row.CompanyName}</TableCell>
                <TableCell sx={{ padding: '0vw 0.69vw' }}>{row.Region}</TableCell>
                {/* <TableCell sx={{ padding: '0.15vw 0.69vw' }}> <Typography variant="body2">
                  {renderChipsWithLimit(row.skills)}
                  </Typography>
                </TableCell>
                <TableCell sx={{ padding: '0.15vw 0.69vw' }}>
                <Typography variant="body2" >
                  {renderChipsWithLimit(row.benefits)}
                  </Typography>
                </TableCell> */}
              </TableRowStyled>
            ))}
          </TableBody>
        </Table>

        {/* Pagination component */}
        <Box sx={{ mt: '1.04vw', mb: '0.15vw', display: 'flex', justifyContent: 'center', borderRadius: '1.11vw', pb: '0.69vw' }}>
          <Pagination
            totalRows={similarJobsData.length}
            rowsPerPage={rowsPerPage}
            currentPage={page + 1}
            onPageChange={(newPage) => handleChangePage(newPage - 1)}
            onRowsPerPageChange={setRowsPerPage}
          />
        </Box>
      </TableWrapper>
    </Box>
  );
};

export default SimilarJobPostings;
