import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../../theme";

interface SessionCountdownProps {
  exp: number; // The expiration time in seconds (UNIX timestamp)
}

const SessionCountdown: React.FC<SessionCountdownProps> = ({ exp }) => {
  const [timeLeft, setTimeLeft] = useState<number>(exp - Math.floor(Date.now() / 1000));

  useEffect(() => {
    // Update the timer every second
    const timer = setInterval(() => {
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      const remainingTime = exp - currentTime; // Calculate remaining time
      setTimeLeft(remainingTime);

      if (remainingTime <= 0) {
        clearInterval(timer); // Clear timer if time is up
      }
    }, 1000);

    return () => clearInterval(timer); // Cleanup timer on component unmount
  }, [exp]);

  // Format timeLeft as MM:SS
  const formatTime = (seconds: number): string => {
    if (seconds <= 0) return "00:00";
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  return (
    <Box sx={{display:'flex',justifyContent:'center',position:'fixed',left:'42.5%',top:'1vh',backgroundColor:theme.palette.error.main,p:'0.5vw 1vw',borderRadius:'1vw', zIndex:100}} >
      {timeLeft > 0 ? (
        <Typography variant="subtitle2" color="#ffff">Your session will expire in: {formatTime(timeLeft)}</Typography>
      ) : (
        <Typography variant="subtitle2" color="#ffff">Your session has expired.</Typography>
      )}
    </Box>
  );
};

export default SessionCountdown;
