import React from 'react';
import {  CardContent, Typography } from '@mui/material';
import { CardContentStyled, CardItem } from './Card.styles';

interface StatusCardProps {
  filterOption:string;
  title: string;
  count: number;
  handleFilterByCard:(value:string)=>void
  handleClearFilterByCard:()=>void;
  cardFilter:boolean
}

const StatusCard: React.FC<StatusCardProps> = ({filterOption, title, count,handleFilterByCard,handleClearFilterByCard,cardFilter}) => {
  return (
    <CardItem sx={{p:1,pb:0,cursor:'pointer'}} onClick={()=>{handleFilterByCard(filterOption)}} onDoubleClick={()=>{handleClearFilterByCard()}}>
      <CardContent sx={{pb:0,'& .MuiCardContent-root':{pb:0}}}>
        <Typography variant="body2">{title}</Typography>
        <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: '0.5vw' }}>
          {count}
        </Typography>
        {cardFilter && (
        <Typography variant="caption" sx={{ color: 'gray', marginTop: '0.5vw', display: 'block' }}>
          Double click to clear the filter
        </Typography>
      )}
      </CardContent>
    </CardItem>
  );
};

export default StatusCard;
