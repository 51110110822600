import React, { useState,useRef } from 'react';
import { Box, Button, TextField, Select, MenuItem, FormControl,InputLabel,FormHelperText, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@mui/material';
import { theme } from '../../../../../theme';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


interface EmailTemplate {
  EmailNum: number;
  EmailSubject: string;
  EmailBody: string;
}

interface EmailConfigurationProps {
  emailList: string;
  setEmailList: (value: string) => void;
  message: string;
  setMessage: (value: string) => void;
  handleSave: (name: string, subject: string, body: string,emailNum:number|null) => void;
  handleDelete: (id: number | null) => void;
  setIsMessageConfigured: (value: boolean) => void; // New prop to manage configuration state
  emailTemplates: EmailTemplate[];
}

const EmailConfiguration: React.FC<EmailConfigurationProps> = ({
  emailList,
  setEmailList,
  message,
  setMessage,
  handleSave,
  handleDelete,
  setIsMessageConfigured, // Destructure the new prop
  emailTemplates=[]
}) => {
  const [subject, setSubject] = useState('');
  const [selectedEmailId, setSelectedEmailId] = useState<number | null>(null); // State for selected email ID
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const quillRef = useRef<ReactQuill>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleSaveEmail = () => {
    const newErrors: { [key: string]: string } = {};
    if (subject.trim().length === 0) {
      newErrors.subject='Email subject is required.'
    }
    const strippedEmail = message.replace(/<p><\/p>|<p><br><\/p>/g, '').trim();
    if (strippedEmail.length === 0) {
      newErrors.message='Email body is required.'
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return
    }
    handleSave(emailList, subject, message,selectedEmailId)
    setEmailList('');
    setSubject('');
    setMessage('');
    setIsMessageConfigured(false)
  }

  const handleEmailListChange = (event: any) => {
    const newValue = event.target.value;
    setEmailList(newValue);

    // Find the selected template from the messageTemplates array
    const selectedTemplate = emailTemplates.find(
      (template) => template.EmailSubject === newValue
    );

    // If template found, set the respective states
    if (selectedTemplate) {
      setSelectedEmailId(selectedTemplate.EmailNum)
      setSubject(selectedTemplate.EmailSubject);
      setMessage(selectedTemplate.EmailBody);
      setIsMessageConfigured(true)
      // setSelectedUserList(selectedTemplate["UserList"] || []); // Set the user list if present
    } else {
      setSubject('');
      setMessage('');
      setSelectedEmailId(null); // Reset selected email ID
      setIsMessageConfigured(false);
    }
  };
  const handleClear = () => {
    setEmailList('');
    setSubject('');
    setMessage('');
    setSelectedEmailId(null); // Reset selected email ID
    setIsMessageConfigured(false); // Reset the configuration state when cleared
  };

  
  const handleDeleteEmail=()=>{
    handleDelete(selectedEmailId)
    setEmailList('');
    setSubject('');
    setMessage('');
    setSelectedEmailId(null);
    setIsMessageConfigured(false)
    setOpenDeleteDialog(false)
  }

  const handleCancelLogout = () => {
    setOpenDeleteDialog(false);
  };

  const quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline'], // Bold, Italic, Underline
      [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Bullet points and numbered points
      ['link'], // Link
      [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }], // Alignment
      [{ 'indent': '-1' }, { 'indent': '+1' }], // Outdent and Indent
    ],
    clipboard: {
      matchVisual: false, // Prevents Quill from auto-converting visual line breaks to `<p><br></p>`
    },
  };
  

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2.2}
      sx={{
       

        p: '1vw',
        pb: 0,
        backgroundColor: 'background.paper',
        borderRadius: '1vw',
        border: '0.0694vw solid #E4E7EC',
      }}
    >
      
      <Box sx={{ display: 'flex', flexDirection: 'column' }} gap={2}>
        <FormControl size="small" sx={{ mb: '1vw', width: '15vw' }}>
          <InputLabel id="email-list-label">Configured Email List</InputLabel>
          <Select
            labelId="email-list-label"
            id="email-list"
            value={emailList}
            onChange={handleEmailListChange}
            label="Configured email List"
          >
            
            {Array.isArray(emailTemplates) && emailTemplates.length > 0 ? (
              emailTemplates.map((template) => (
                <MenuItem key={template.EmailNum} value={template.EmailSubject}>
                  {template.EmailSubject}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No templates available</MenuItem>
            )}
          </Select>
        </FormControl>
          
       
        <TextField
          fullWidth
          label="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          variant="outlined"
          size="small"
          sx={{ mb: '1vw' }}
          error={!!errors.subject}
          helperText={errors.subject}
          autoComplete='off'
        />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '2vw' }}>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={message}
          onChange={setMessage}
          modules={quillModules}
          style={{ height: '35vh', marginBottom: '0vw' }}
        />
        {errors.message && (
          <FormHelperText error>{errors.message}</FormHelperText> // Error message for ReactQuill
        )}
      </Box>

      <Box
        display="flex"
        justifyContent="flex-end"
        gap={2}
        sx={{
          borderBottomLeftRadius: '1.11vw',
          borderBottomRightRadius: '1.11vw',
          backgroundColor: theme.palette.primary.light,
          p: '1vw 3vw', // Padding adjusted to match the container
          width: 'calc(100% + 2vw)',
          position: 'relative',
          right: '1vw', // Reset any offset
        }}
      >
        <Button variant="outlined" color="secondary" onClick={handleClear} sx={{ minWidth: '100px' }}>
          Clear
        </Button>
        <Button
          variant="outlined"
          sx={{
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            minWidth: '100px',
          }}
          onClick={()=>{setOpenDeleteDialog(true)}}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveEmail}
          sx={{
            minWidth: '100px',
          }}
        >
          Save
        </Button>
      </Box>

      <Dialog open={openDeleteDialog} onClose={handleCancelLogout}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Are you sure you want to Delete Message?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelLogout} sx={{color:theme.palette.text.secondary}}>
            Cancel
          </Button>
          <Button
            onClick={handleDeleteEmail}
           
            variant="contained"
            sx={{
              bgcolor: theme.palette.error.main,
              '&:hover': {
                bgcolor: theme.palette.error.dark,
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
};

export default EmailConfiguration;
