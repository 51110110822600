import React from 'react';
import './App.css';
import SignUp from './Components/Pages/SignUp/SignUp';
import SignIn from './Components/Pages/SignIn/SignIn';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import JobListing from './Components/Pages/JobManagement/JobListing/JobListing';
import Dashboard from './Components/Pages/Dashboard/Dashboard';
import CreateJobPosting from './Components/Pages/JobManagement/CreateJobPosting/CreateJobPosting';
import User from './Components/Pages/Admin/User/User';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from './theme';
import ProtectedRoute from './Components/Pages/SignIn/ProtectedRoute';
import DefaultPage from './Components/Pages/DefaultPage';
import Communication from './Components/Pages/Admin/Communication/Communication';
import DashboardMessage from './Components/Pages/Admin/Communication/DashboardMessages/DashboardMessage';
import ManageEmailTemplates from './Components/Pages/Admin/Communication/ManageEmailTemplates/ManageEmailTemplates';
import ChangePassword from './Components/Pages/SignIn/ChangePassword/ChangePassword'; // Import ChangePassword
import ForgetPassword from './Components/Pages/SignIn/ForgetPassword/ForgetPassword';
import CandidateProfile from './Components/Pages/CandidateManagement/CandidateProfile/CandidateProfile'
import CandidateExperience from './Components/Pages/CandidateManagement/CandidateExperience/CandidateExperience';
import AssignTask from './Components/Pages/CandidateManagement/AssignTask/AssignTask';
import CandidateDetails from './Components/Pages/Preboarding/CandidateDetails';
import CandidatePreboarding from './Components/Pages/Preboarding/CandidatePreboarding/CandidatePreboarding';
import YourOffer from './Components/Pages/Preboarding/CandidatePreboarding/YourOffer/YourOffer';
import ImportCandidates from './Components/Pages/CandidateManagement/ImportCandidates/ImportCandidates';
import RoleProtectedRoute from './Components/Pages/SignIn/RoleProtectedRoute';
import PersonalDetails from './Components/Pages/Preboarding/CandidatePreboarding/PersonalDetails/PersonalDetails';
import Documents from './Components/Pages/Preboarding/CandidatePreboarding/Documents/Documents';
import OnboardingExperience from './Components/Pages/Preboarding/CandidatePreboarding/OnBoardingExperience/OnBoardingExperience';
import { PreboardingProvider } from './Components/Pages/Preboarding/CandidatePreboarding/PreboardingContext';
import ImportNewJob from './Components/Pages/InternalJob/ImportNewJob';
import OTPVerification from './Components/Pages/Preboarding/CandidatePreboarding/OTPVerification/OTPVerification';
import AccessDenied from './Components/Pages/Preboarding/CandidatePreboarding/OTPVerification/AccessDenied';
import ThankYou from './Components/Pages/Preboarding/CandidatePreboarding/OTPVerification/ThankYou';
import CandidateLinkExpired from './Components/Pages/Preboarding/CandidatePreboarding/OTPVerification/CandidateLinkExpired';

const router = createBrowserRouter([
  {
    path: "/:tenant/SignUp",
    element: <SignUp />,
  },
  {
    path: "/:tenant",
    element: <SignIn />,
  },
  {
    path: "/:tenant/SignIn",
    element: <SignIn />,
  },
  {
    path: "/:tenant/change-password", // Add this line for ChangePassword
    element: <ChangePassword />,
  },
  {
    path: "/:tenant/forgot-password",
    element: <ForgetPassword />
  },
  {
    path:"/:tenant/OTPVerification",
    element:<OTPVerification/>
  }
  ,{
    path: "/:tenant/AccessDenied",
    element: <AccessDenied />, // The AccessDenied page
  },{
    path: "/:tenant/ThankYou",
    element: <ThankYou />, // The AccessDenied page
  },
  {
    path: "/:tenant/LinkExpired",
    element: <CandidateLinkExpired />, // The AccessDenied page
  },
  {
    path: "/:tenant/*",
    element: (
        <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "Joblisting",
        element: <JobListing />,

      },
      // {
      //   path: "/Dashboard",
      //   element: <JobListing />,
      // },
      {
        path: "Joblisting/create-job",
        element: <CreateJobPosting />
   
      },
      {
        path: "Users",
        element: (
          <RoleProtectedRoute requiredRoles={['Admin', 'Super Admin']}>
            <User />
          </RoleProtectedRoute>
        ),
      },
      {
        path: "Communication",
        element: (
          <RoleProtectedRoute requiredRoles={['Admin', 'Super Admin']}>
            <Communication />
          </RoleProtectedRoute>
        ),
        children: [
          {
            path: "dashboard-messages",
            element: <DashboardMessage />
          },
          {
            path: "email-templates",
            element: <ManageEmailTemplates />
          },

        ]
      },
      {
        path: "candidateprofile",
        element: <CandidateProfile />,
      },
      {
        path: "CandidateExperience",
        element: <CandidateExperience />,
      },
      {
        path: "AssignTask",
        element: <AssignTask />,

      },
      {
        path: "ImportCandidates",
        element:<ImportCandidates /> ,
      },
      {
        path: "CandidateDetails",
        element: <CandidateDetails />,
      },
      {
        path: "CandidateDetails/CandidatePreboarding", // This should match the path you navigate to
        element: <PreboardingProvider><CandidatePreboarding /></PreboardingProvider>,
        children: [
          // Add nested routes if needed, e.g.:
          {path: "your-offer", element: <YourOffer />},
          { path: "personal-details", element: <PersonalDetails /> },
          { path: "documents", element: <Documents /> },
          { path: "onboarding-experience", element: <OnboardingExperience /> },
        ]
      },
      {
        path:"importnewjob",
        element:<ImportNewJob />
      }

    ]
  },
  // You can add more routes here
  {
    path: "/", // Default landing page
    element: <DefaultPage />, // Change to your desired default path
  },
]);


function App() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
