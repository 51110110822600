import React, { useState,useRef } from 'react';
import { Box, Button, TextField, Select, MenuItem, FormControl, InputLabel,FormHelperText, Dialog, DialogTitle, DialogContent, DialogActions, Typography} from '@mui/material';
import { theme } from '../../../../../theme';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface MessageTemplate {
  MessageNum: number;
  MessageSubject: string;
  MessageBody: string;
}
interface MessageConfigurationProps {
  messageList: string;
  setMessageList: (value: string) => void;
  configureDashboardMessage: string;
  setConfigureDashboardMessage: (value: string) => void;
  handleSave: (messageList: string, dashboardMessage: string, configureDashboardMessage: string,messageNum:number|null) => void;
  handleDelete: (id: number | null) => void;
  setIsMessageConfigured:(value:boolean)=>void;
  messageTemplates: MessageTemplate[]
}


const MessageConfiguration: React.FC<MessageConfigurationProps> = ({
  messageList,
  setMessageList,
  configureDashboardMessage,
  setConfigureDashboardMessage,
  handleSave,
  handleDelete,
  messageTemplates=[],
  setIsMessageConfigured
}) => {
  const [dashboardMessage, setDashboardMessage] = useState(''); // State for configured dashboard message
  const [selectedUserList, setSelectedUserList] = useState<string[]>([]); // State for user list
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [selectedMessage, setSelectedMessage] = useState<number | null>(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const quillRef = useRef<ReactQuill>(null);

  const handleSaveMessage = () => {
    const newErrors: { [key: string]: string } = {};
    if (dashboardMessage.trim().length === 0) {
      newErrors.dashboardmessage='Message  subject is required.'
    }
    const strippedMessage = configureDashboardMessage.replace(/<p><\/p>|<p><br><\/p>/g, '').trim();
    if (strippedMessage.length === 0) {
      newErrors.configuredashboardmessage='Message body is required.'
    }
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return
    }
    
    handleSave(messageList, dashboardMessage, configureDashboardMessage,selectedMessage)
    setMessageList('')
    setDashboardMessage('')
    setConfigureDashboardMessage('')
    setIsMessageConfigured(false)
  }

  const handleMessageListChange = (event: any) => {
    const newValue = event.target.value;
    setMessageList(newValue);

    // Find the selected template from the messageTemplates array
    const selectedTemplate = messageTemplates.find(
      (template) => template.MessageSubject === newValue
    );

    // If template found, set the respective states
    if (selectedTemplate) {
      setSelectedMessage(selectedTemplate.MessageNum)
      setDashboardMessage(selectedTemplate.MessageSubject);
      setConfigureDashboardMessage(selectedTemplate.MessageBody);
      setIsMessageConfigured(true)
      // setSelectedUserList(selectedTemplate["UserList"] || []); // Set the user list if present
    } else {
      setDashboardMessage('');
      setConfigureDashboardMessage('');
      setSelectedUserList([]);
      setIsMessageConfigured(false)
    }
  };

  const handleClear = () => {
    // Clear the message list, dashboard message, and editor content
    setMessageList('');
    setDashboardMessage('');
    setConfigureDashboardMessage('');
    setSelectedMessage(null)
    setSelectedUserList([]);
    setIsMessageConfigured(false)
  };

  

  const handleDeleteMessage = () => {
    handleDelete(selectedMessage)
    handleClear()
    setOpenDeleteDialog(false)
  }

  const handleCancelLogout = () => {
    setOpenDeleteDialog(false);
  };

  const quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline'], // Bold, Italic, Underline
      [{ list: 'ordered' }, { list: 'bullet' }], // Bullet points and numbered points
      ['link'], // Link
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }], // Align options
      [{ 'indent': '-1' }, { 'indent': '+1' }], 
    ],
    clipboard: {
      matchVisual: false, // Prevents Quill from auto-converting visual line breaks to `<p><br></p>`
    },
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={2.2}
      sx={{
        
        p: '1vw',
        pb: 0, // Padding inside the box
        backgroundColor: 'background.paper', // Use theme's paper background color
        borderRadius: '1vw', // Rounded corners
        border: `0.0694vw solid ${theme.palette.custom.borderLight}`, // Adds a shadow for a 'contained' effect
      }}
    >
      
      <Box sx={{ display: 'flex', flexDirection: 'column' }} gap={2}>
        {/* Dropdown for selecting the message template using Select */}
        <FormControl size="small" sx={{ mb: '1vw', width: '15vw' }}>
          <InputLabel id="message-list-label">Configured Message List</InputLabel>
          <Select
            labelId="message-list-label"
            id="message-list"
            value={messageList}
            onChange={handleMessageListChange}
            label="Configured Message List"
          >
            {Array.isArray(messageTemplates) && messageTemplates.length > 0 ? (
              messageTemplates.map((template) => (
                <MenuItem key={template.MessageNum} value={template.MessageSubject}>
                  {template.MessageSubject}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No templates available</MenuItem>
            )}
          </Select>
        </FormControl>

        
        {/* TextField for configured dashboard message */}
        <TextField
          fullWidth
          label="Configure Dashboard Message"
          value={dashboardMessage} // Bind the dashboard message state here
          onChange={(e) => setDashboardMessage(e.target.value)} // Update dashboard message state
          variant="outlined"
          size="small"
          sx={{ mb: '1vw' }}
          error={!!errors.dashboardmessage}
          helperText={errors.dashboardmessage}
          autoComplete='off'
        />
      </Box>

      {/* Message Editor using React Quill */}
      <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '2vw' }}>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          className="my-quill-editor"
          value={configureDashboardMessage}
          onChange={setConfigureDashboardMessage}
          modules={quillModules}
          style={{ height: '40vh', marginBottom: '0vw', width: '100%' }} // Adjust height based on viewport height
        />
        {errors.configuredashboardmessage && (
          <FormHelperText error>{errors.configuredashboardmessage}</FormHelperText> // Error message for ReactQuill
        )}
      </Box>

      {/* Action buttons */}
           {/* Action buttons */}
           <Box
        display="flex"
        justifyContent="flex-end"
        gap={2}
        sx={{
          borderBottomLeftRadius: '1.11vw',
          borderBottomRightRadius: '1.11vw',
          backgroundColor: theme.palette.primary.light,
          p: '1vw 3vw', // Padding adjusted to match the container
          width: 'calc(100% + 2vw)',
          position: 'relative',
          right: '1vw', // Reset any offset
        }}
      >
        <Button variant="outlined" color="secondary" onClick={handleClear} sx={{ minWidth: '100px' }}>
          Clear
        </Button>
        <Button
          variant="outlined"
          sx={{
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            minWidth: '100px',
          }}
          onClick={()=>{setOpenDeleteDialog(true)}}
        >
          Delete
        </Button>
        <Button variant="contained" color="primary" onClick={handleSaveMessage} sx={{ minWidth: '100px' }}>
          Save
        </Button>
      </Box>
      
      <Dialog open={openDeleteDialog} onClose={handleCancelLogout}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Are you sure you want to Delete Message?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelLogout} sx={{color:theme.palette.text.secondary}}>
            Cancel
          </Button>
          <Button
            onClick={handleDeleteMessage}
           
            variant="contained"
            sx={{
              bgcolor: theme.palette.error.main,
              '&:hover': {
                bgcolor: theme.palette.error.dark,
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MessageConfiguration;
