import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Grid, Table, TableBody, TableCell, TableHead, TableRow, Button,
  Paper, IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress
} from '@mui/material';
import { Link } from 'react-router-dom';
import {
  Container, Header, ActionButtons, TableWrapper, TableRowStyled, TableDescription, OpenStatus,
  CloseStatus,
  OnHoldStatus
} from './JobListing.styles';
import FilterSortDialog from './FilterSortDialog/FilterSortDialog';
import Pagination from '../../../Common/Pagination/pagination';
import StatusCard from '../../../Common/Card/Card';
import { theme } from '../../../../theme';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import APIBaseURL from '../../../../AppConfig/AppConfig.json'
import axios from 'axios';
import { WidthFull } from '@mui/icons-material';

interface JobData {
  JobNum: number;
  JobTitle: string;
  Department: string;
  Status: string;
  CreatedDate: string;
  CreatedBy: string;
  UpdatedDate: string;
  UpdatedBy: string;
  JobCode: string;
  year_of_experience?: number;
  ctc?: number;
  skills?: string[];
  recruiter?: string;
  [key: string]: any;
  DeletedItem:boolean;
}



const JobListing: React.FC = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // For delete confirmation dialog
  const [jobData, setJobData] = useState<JobData[]>([]);
  const [filteredData, setFilteredData] = useState<JobData[]>([]);
  const { tenant } = useParams<{ tenant: string }>();
  const baseURL = window.location.origin.endsWith('/')
    ? window.location.origin
    : `${window.location.origin}/`;
  const columnsData = ['Job title', 'Function', 'Job status', 'Created date', 'Created by', 'Updated date', 'Updated by', 'Action']
  const navigate = useNavigate()
  const [jobNumToDelete, setJobNumToDelete] = useState<number | null>(null);
  const token = localStorage.getItem('access_token')
  const [jobDataAvailable,setJobDataAvailable]=useState(false)
  const[loading,setLoading]=useState(true)
  const [activeFilter,setActiveFilter]=useState<string | null>(null)
  useEffect(() => {
    if (!token) {
      navigate(`/${tenant}/SignIn`)
      return
    }
    else {
      const fetchJobData = async () => {
        try {
          const response = await axios.get(`${APIBaseURL.apiBaseUrl}/job/jobs`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'X-Tenant': tenant,
                'X-App': baseURL
              }
            }
          )
          const data: JobData[] = Array.isArray(response.data) ? response.data : []; // Ensure it's an array
          setJobData(data.filter((job)=>!job.DeletedItem));
          setFilteredData(data.filter((job)=>!job.DeletedItem));
          setJobDataAvailable(true)
        } catch (error) {
          console.error('Error fetching job data:', error);
          setFilteredData([]);
        }finally{
          setLoading(false)
        }
      };
      fetchJobData();
    }

  }, []);


  const formatEpochToDate = (epochTime: any) => {
    const date = new Date(epochTime * 1000); // Convert seconds to milliseconds
    return date.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleDeleteJob = (jobNum: number) => {
    setJobNumToDelete(jobNum); // Set job ID to delete
    setDeleteDialogOpen(true); // Open delete confirmation dialog
  };

  // Confirm deletion
  const confirmDeleteJob = async () => {
    if (jobNumToDelete !== null) {
      if (!token) {
        navigate(`/${tenant}/SignIn`)
        return
      }
      try {
        const response = await axios.post(`${APIBaseURL.apiBaseUrl}/job/delete_job`, {
          "JobNum": jobNumToDelete
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Tenant': tenant,
            'X-App': baseURL
          }
        })
        if (response.status === 200) {
          setFilteredData((prevData) => prevData.filter((job) => job.JobNum !== jobNumToDelete));
          setJobData((prevData) => prevData.filter((job) => job.JobNum !== jobNumToDelete));
        }
      } catch (error) {
        console.log(error)
      }
    }
    setDeleteDialogOpen(false); // Close dialog after deletion
    setJobNumToDelete(null); // Reset job ID to delete
  };

  const cancelDeleteJob = () => {
    setDeleteDialogOpen(false); // Close delete dialog without deleting
    setJobNumToDelete(null); // Reset job ID to delete
  }

  const handleApplyFilters = (newFilters: {
    sortBy: string;
    selectedFilters: string[];
    filterText: { [key: string]: string };
    jobStatus: string;
  }) => {
    // setFilters(newFilters);
    applyFilters(newFilters);
    handleCloseDialog();
  };

  const applyFilters = (newFilters: any) => {
    let tempData = [...jobData];

   // Filter by selected filters (like jobTitle, function, etc.)
   newFilters.selectedFilters.forEach((filter: string) => {
    let filterText = newFilters.filterText[filter]?.trim().toLowerCase(); // Handle any extra spaces and case insensitivity
    if (filterText) { // Only filter if the filterText is not empty or undefined
      // Handle "Function" and change it to "Department"
    if (filter === "Function") {
      filter = "Department"; // Replace "Function" with "Department"
    }
      tempData = tempData.filter(job => {
        const jobFieldvalue=job[filter]?.toLowerCase()
        return jobFieldvalue.includes(filterText)
      });
    }
  });

   // Filter by job status
  if (newFilters.jobStatus) {
    tempData = tempData.filter(job => job.Status === newFilters.jobStatus);
  }

    // Sorting
  if (newFilters.sortBy === 'CreatedDate') {
    tempData.sort((a, b) => {
      const dateA = new Date(a.CreatedDate).getTime();
      const dateB = new Date(b.CreatedDate).getTime();
      return dateA - dateB; // Ascending order
    });
  }

    setFilteredData(tempData);
    setPage(1);
  };

  const handleSort = (column: string) => {
    // Sorting logic here...
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };


  const handleRowsPerPageChange = (rows: number) => {
    setRowsPerPage(rows);
    setPage(1);
  };

  const handleEditJob = (jobNum: number) => {
    navigate(`/${tenant}/Joblisting/create-job`, { state: { jobNum } })
  }
  const handleViewJob = (jobNum: number) => {
    navigate(`/${tenant}/Joblisting/create-job`, { state: { jobNum,ReadOnly:true } })
  }

  const handleFilterByCard = (status: string) => {
    setActiveFilter(status); // Set the active filter
    const filteredJobs = jobData.filter((job) => job.Status === status); // Filter only for display
    setFilteredData(filteredJobs);
  };

  const handleClearFilterByCard = () => {
    setActiveFilter(null); // Clear the active filter
    setFilteredData(jobData); // Reset to the original dataset
  };
  if(loading){
    return(
      <Box sx={{
        height: '90vh', // Ensures full height
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', width: '100%'}}>
        <CircularProgress disableShrink/>
      </Box>
    )
  }

  if(!jobDataAvailable){
    return(
      <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height="90vh"  // Ensures the container takes full viewport height
    textAlign="center"  // Centers the text
    overflow="hidden"  // Prevents scrolling
    sx={{ width: '100%',pt:0 }}  // Ensures full width
  >
    <i className="fa-solid fa-file-circle-xmark fa-5x" style={{ padding:'0.5vw',marginLeft:'0.5vw',color: theme.palette.text.disabled, marginBottom: 8 }}></i>
  
    <Typography variant="subtitle1" sx={{ mb: 2 }}>
      You haven't created any jobs. Start creating a job and find the best candidates.
    </Typography>
    
    <Link
      to={`/${tenant}/Joblisting/create-job`}
      style={{ textDecoration: 'none' }} // Ensures the link doesn't have an underline
    >
      <Button variant="contained" color="primary">
        Create
      </Button>
    </Link>
  </Box>
    )
  }
  return (
    <Box 
    sx={{  
        mr:'0.2vw',
        width:'90.5vw',              
        boxSizing: 'border-box', // Ensure padding does not affect width
        display: 'flex',
        pr:0,
        flexDirection: 'column',
        gap: 1,
    }}
>
    <Container sx={{ margin: '0 0vw 0 1vw', p: 0, mb: 2,pr:'0.28vw',width:'100%' }}>
      <Header>
        <Box>
          <Typography variant="h5">
            Job listing
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            See numbers of position open, closed, and On-hold here
          </Typography>
        </Box>
        <Link
          style={{ color: 'primary', height: 'inherit', textDecoration: 'none' }}
          to={`/${tenant}/Joblisting/create-job`}
        >
          <Button
            variant="contained"
            sx={{ whiteSpace: 'nowrap', display: 'flex', gap: '0.8vw' }}
            color="primary"
          >
            <i className="fa-solid fa-plus " /> Create new job
          </Button>
        </Link>
      </Header>

      <Grid container spacing={2} sx={{ mb: '1vw' }}>
        <Grid item xs={12} sm={4}>
          <StatusCard
            filterOption='Open'
            title="Total Open Position"
            count={jobData.filter(job => job.Status !== 'Close' && job.Status !== 'On-hold').length}
            handleFilterByCard={handleFilterByCard}
            handleClearFilterByCard={handleClearFilterByCard}
            cardFilter={activeFilter === 'Open'} // Highlight active card
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatusCard
            filterOption='Close'
            title="Total Closed Position"
            count={jobData.filter(job => job.Status === 'Close').length}
            handleFilterByCard={handleFilterByCard}
            handleClearFilterByCard={handleClearFilterByCard}
            cardFilter={activeFilter === 'Close'} // Highlight active card
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatusCard
            filterOption='On-hold'
            title="Position On-hold"
            count={jobData.filter(job => job.Status === 'On-hold').length}
            handleFilterByCard={handleFilterByCard}
            handleClearFilterByCard={handleClearFilterByCard}
            cardFilter={activeFilter === 'On-hold'} // Highlight active card
          />
        </Grid>

      </Grid>

      <ActionButtons>
        {/* <Button variant="outlined">Duplicate post</Button> */}
        <Button
          variant="outlined"
          sx={{ color: theme.palette.primary.main, borderColor: theme.palette.primary.main }}
          onClick={handleOpenDialog}
        >
          Filter & Sort
        </Button>
      </ActionButtons>

      <FilterSortDialog open={dialogOpen} onClose={handleCloseDialog} onApply={handleApplyFilters} />

      <TableWrapper>

        <Box sx={{ overflowX: 'auto' }}>
          <Table >
            <TableHead>
              <TableRow>
                {columnsData.map((column) => (
                  <TableCell key={column} sx={{
                    color: theme.palette.text.secondary,
                    
                    padding: '0.8vh 2.4vw',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: ' 13.89vw',
                    minWidth: ' 6.94vw',
                  }}>
                    <Typography variant='body3'>
                    {column.charAt(0).toUpperCase() + column.slice(1).replace(/_/g, ' ')}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody >
              {filteredData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columnsData.length} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    No jobs data available
                  </TableCell>
                </TableRow>
              ) : (
                filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((data, index) => (
                  <TableRowStyled key={data.JobNum} iseven={(index % 2 === 0).toString()}>
                    {/* <TableDescription >{data.JobNum}</TableDescription> */}
                    <TableDescription>{data.JobTitle}</TableDescription>
                    <TableDescription>{data.Department}</TableDescription>
                    <TableDescription>
                      {data.Status === 'Open' ? (
                        <OpenStatus>{data.Status}</OpenStatus>
                      ) : data.Status === 'Close' ? (
                        <CloseStatus>{data.Status}</CloseStatus>
                      ) :(
                        <OnHoldStatus>{data.Status}</OnHoldStatus>
                      ) 
                      }
                    </TableDescription>
                    <TableDescription>{formatEpochToDate(data.CreatedDate)}</TableDescription>
                    <TableDescription>{data.CreatedBy}</TableDescription>
                    <TableDescription>{data.UpdatedDate ? formatEpochToDate(data.UpdatedDate) : ' '}</TableDescription>
                    <TableDescription>{data.UpdatedBy || ' '}</TableDescription>
                    <TableDescription>
                      <Box sx={{ display: 'flex', gap: '8px', color: theme.palette.primary.main }}>
                      <IconButton onClick={() => { handleViewJob(data.JobNum) }} sx={{ color: theme.palette.primary.main }}>
                          <i className="fa-solid fa-eye fa-xs"></i>
                        </IconButton>
                        <IconButton onClick={() => { handleEditJob(data.JobNum) }} sx={{ color: theme.palette.primary.main }}>
                          <i className="fa-solid fa-pen fa-xs"></i>
                        </IconButton>
                        <IconButton onClick={() => { handleDeleteJob(data.JobNum) }} sx={{ color: theme.palette.primary.main }}>
                          <i className="fa-solid fa-trash fa-xs"></i>
                        </IconButton>
                      </Box>
                    </TableDescription>
                  </TableRowStyled>
                ))
              )}
            </TableBody>
          </Table>
        </Box>

        <Box sx={{ mt: '2.5vh', display: 'flex', mb: 2, mx: 2, justifyContent: 'center' }}>
          <Pagination
            totalRows={filteredData.length}
            rowsPerPage={rowsPerPage}
            currentPage={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Box>
      </TableWrapper>

      <Dialog
        open={deleteDialogOpen}
        onClose={cancelDeleteJob}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this job posting? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDeleteJob} sx={{color:theme.palette.text.secondary}}>
            Cancel
          </Button>
          <Button onClick={confirmDeleteJob} sx={{ bgcolor: theme.palette.error.main }} variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  
  
  </Box>
  );
};

export default JobListing;