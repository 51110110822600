import React, { useEffect, useState } from 'react';
import { Typography, Box, Checkbox, Divider, Button, CircularProgress} from '@mui/material';
import DeclineOffer from './DeclineOffer';
import { usePreboarding } from '../PreboardingContext';
import 'react-quill/dist/quill.snow.css';
import {  useParams,useOutletContext } from 'react-router-dom';
import axios from 'axios';
import APIBaseURL from '../../../../../AppConfig/AppConfig.json'


const YourOffer: React.FC = () => {
  const [reviewed, setReviewed] = useState(false);
  const [declinePopupOpen, setDeclinePopupOpen] = useState(false);
  const [offerResponded, setOfferResponded] = useState(false);
  const [responseDate, setResponseDate] = useState<string | null>(null);
  const [offerStatus, setOfferStatus] = useState(false);
  const { candidateOfferletterData,emailBody,candidateNum ,candidateJobNum,userRole,candidateMapID,fetchOfferData} = useOutletContext<{ candidateOfferletterData: { ActionDate: number, CandidateNum: number, Offerdate: string, OfferReviewed: boolean, OfferStatus: boolean, RecruiterName: string ,JobNum:number};emailBody:string | null ;candidateNum:any;candidateJobNum:any;userRole:string | null;candidateMapID:any,fetchOfferData:any}>()
  const { setOfferAccepted, setOfferDeclined } = usePreboarding();
  const { tenant } = useParams<{ tenant: string }>();
  const baseURL = window.location.origin.endsWith('/')
    ? window.location.origin
    : `${window.location.origin}/`;
 


  const formatEpochDate = (epochTime: number) => {
    // Create a new Date object using the epoch time in milliseconds
    const date = new Date(epochTime * 1000); // Multiply by 1000 if epoch time is in seconds

    // Format the date to MM/DD/YYYY, HH:mm:ss
    return date.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });
  };



  const handleDeclineClick = () => {
    setDeclinePopupOpen(true); // Open the DeclineOffer popup
  };

  useEffect(() => {
    if (candidateOfferletterData) {
      setReviewed(candidateOfferletterData.OfferReviewed)
      setOfferResponded(candidateOfferletterData.OfferReviewed)
      setResponseDate(formatEpochDate(candidateOfferletterData.ActionDate))
      if (candidateOfferletterData.OfferStatus) {
        setOfferStatus(candidateOfferletterData.OfferStatus)
      }
      else {
        setOfferStatus(candidateOfferletterData.OfferStatus)
      }
    }
  }, [candidateOfferletterData])

  useEffect(()=>{
    if(candidateMapID){
      fetchOfferData()
    }
  },[candidateMapID])

  const handleAcceptClick = async () => {
    setOfferAccepted(true); // Set offer as accepted in the context
    const currentDate = new Date();
    setResponseDate(currentDate.toLocaleString()); // Set the date and time when the offer is accepted
    setOfferStatus(true); // Set the offer status as "Accepted"
    setOfferResponded(true); // Set offer as responded, disabling further actions

   
    if (candidateNum && candidateJobNum && candidateMapID) {
      try {
        const response = await axios.post(`${APIBaseURL.apiBaseUrl}/job/candidate_offerletter_action`, {
          "OfferStatus": true, "OfferReviewed": reviewed, "MapID": candidateMapID,
        }, {
          headers: {
            'X-Tenant': tenant,
            'X-App': baseURL,
          }
        })
        if (response.status === 200) {
          setReviewed(reviewed)
          setOfferStatus(true)
        }
      } catch (error) {
        console.log(error)
      }
    }
  };

  const handleDeclineClose = async (reason: string) => {
    setDeclinePopupOpen(false);
    const currentDate = new Date();
    setResponseDate(currentDate.toLocaleString()); // Set the date and time when the offer is declined
    setOfferDeclined(true); // Set offer as declined in the context
    setOfferStatus(false); // Set the offer status as "Declined"
    setOfferResponded(true); // Set offer as responded after declining

  
    if (candidateNum && candidateJobNum && candidateMapID) {
      try {
        const response = await axios.post(
          `${APIBaseURL.apiBaseUrl}/job/candidate_declineoffer_data`,
          {
            "OfferStatus": false, // Declined
            "OfferReviewed": reviewed,
            "CandidateNum": candidateNum,
            "Reason": reason,
            "JobNum":candidateJobNum,
            "MapID":candidateMapID
          },
          {
            headers: {
              'X-Tenant': tenant,
              'X-App': baseURL,
            },
          }
        );
        if (response.status === 200) {
          setReviewed(reviewed)
        }
      } catch (error) {
        console.log(error);
      }
    }
  };


  return (
    <Box sx={{ pb:2,pt:1, width: '100%', }}>
      {/* Heading and Highlighter */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mr: 2 }}>
          Offer Letter
        </Typography>
        
      </Box>
      
        <Box
          sx={{
            backgroundColor: '#e0e0e0',
            padding: 1.5,
            borderRadius: '0.547vw',
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'left',
            marginTop: 2,
          }}
        >
          Your Offer Letter
        </Box>
  
        <Box sx={{ my: 2, fontFamily: 'Arial', fontSize: '1rem' }}>

        {emailBody ? (
          <Box dangerouslySetInnerHTML={{ __html: emailBody }} />
        )
          : (
            // <Typography>No offer letter available.</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}> <CircularProgress /></Box>
          )
        }
      </Box>
      {/* )} */}

      {userRole===null?(<Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
        <Checkbox
          checked={reviewed}
          onChange={(e) => setReviewed(e.target.checked)}
          color="primary"
          disabled={offerResponded} // Disable checkbox if offer is accepted or declined
        />
        <Typography variant="body2">I have reviewed the offer letter</Typography>
      </Box>):(<></>)}
      

          <Divider sx={{ my: 3 }} />

      {/* Buttons */}

      {userRole===null?(
        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: '1.111vw' }}>
        {/* <Button variant="text" sx={{ textAlign: 'left' }}>
          Download Your Offer Letter
        </Button> */}
        {!offerResponded && (
          <Box sx={{ display: 'flex' }}>
            <Button
              variant="outlined"
              sx={{
                marginRight: 2, '&:disabled': {
                  opacity: 1,/* Set your desired opacity */
                  cursor: 'not-allowed' /* Optional: change the cursor to indicate the button is disabled */
                }
              }}
              onClick={handleDeclineClick}
              disabled={!reviewed} // Enable only if checkbox is checked
            >
              Decline
            </Button>
            <Button
              variant="contained"
              onClick={handleAcceptClick}
              disabled={!reviewed} // Enable only if checkbox is checked
              sx={{
                marginRight: 2, '&:disabled': {
                  opacity: '100%',/* Set your desired opacity */
                  cursor: 'not-allowed' /* Optional: change the cursor to indicate the button is disabled */
                }
              }}
            >
              Accept Offer
            </Button>
          </Box>
        )}
      </Box>
      ):(<></>)}
      
    
      {/* Show Accepted or Declined status with date */}
      {offerResponded &&(
          <Box sx={{ mt: '1.111vw', fontWeight: 'bold' }}>
            <Typography variant="body2">
              Offer {offerStatus === true ? 'Accepted' : 'Declined'} on {responseDate}
            </Typography>
          </Box>
        )}

      {/* DeclineOffer component */}
      <DeclineOffer
        open={declinePopupOpen}
        onClose={handleDeclineClose}
        setDeclinePopupOpen={setDeclinePopupOpen}
      />
    </Box>
  );
};

export default YourOffer;
