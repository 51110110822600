import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableHead,
    TableRow,
    CircularProgress,
} from "@mui/material";
import {  useNavigate,useParams } from "react-router-dom"; // Import useNavigate
import Pagination from "../../Common/Pagination/pagination";
import {
    Container,
    TableWrapper,
    StyledTableCell,
    TableRowStyled,
    CandidateAvatar,
} from './CandidateDetails.styles';
import { theme } from "../../../theme";  // Ensure theme import for consistent colors
import APIBaseURL from '../../../AppConfig/AppConfig.json'
import axios from "axios";
import { UserAvatar } from "../CandidateManagement/AssignTask/AssignTask";


interface candidateData {
    CandidateNum: number,
    CandidateName: string,
    CandidateEmail: string,
    CandidatePosition: string,
    CandidateFunction: string,
    CandidateRecruiter: string,
    CandidateStatus: string,
    JobNum:number,
    DeletedItem:boolean,
    MapID:number,
    DeletedCandidate:boolean
}



// CandidateDetails Component
const CandidateDetails: React.FC = () => {
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [candidateList, setCandidateList] = useState<candidateData[]>([])
    const [filteredData, setFilteredData] = useState<candidateData[]>([]);
    const navigate = useNavigate(); // Initialize useNavigate
    const { tenant } = useParams<{ tenant: string }>();
    const baseURL = window.location.origin.endsWith('/')
        ? window.location.origin
        : `${window.location.origin}/`;
    const token=localStorage.getItem('access_token')
    const candidate_access_token=localStorage.getItem('access_token')
    const[loading,setLoading]=useState(true)

    const handlePageChange = (newPage: number) => setPage(newPage);
    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        setRowsPerPage(newRowsPerPage);
        setPage(1); // Reset to first page after changing rows per page
    };
    const handleRowClick = (candidate:candidateData) => {
        navigate(`/${tenant}/candidatedetails/CandidatePreboarding/your-offer`,{state:{candidate}}); // Explicit path if necessary
    };
    
    useEffect(()=>{
        const fetchData = async () => {
          if(!token && !candidate_access_token){
            navigate(`/${tenant}/AccessDenied`)
            return
          }
            if (!token) {
                navigate(`/${tenant}/SignIn`)
                return
            }
            else {
                try {
                    const response = await axios.get(`${APIBaseURL.apiBaseUrl}/job/get_candidates`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'X-Tenant': tenant,
                            'X-App': baseURL,
                        }
                    })
                    if (response.status === 200) {
                        const data: candidateData[] = Array.isArray(response.data) ? response.data : []; // Ensure it's an array
                        setCandidateList(data.filter((candidate)=>!candidate.DeletedCandidate))
                    }
                } catch (error) {
                    console.log(error)
                }finally {
                  setLoading(false);
              }
            }
        }
        fetchData()
    },[])

    

    useEffect(()=>{
        setFilteredData(candidateList.filter((candidate)=>candidate.CandidateStatus==='Pre-boarding' && !candidate.DeletedCandidate))
    },[candidateList])

    return (
        <Container sx={{ width: '100%' }}>
        <Box px={'1vw'}>
          <Typography variant="h5" sx={{ pb: '1.111vw' }}>Candidates List</Typography>
  
          {/* Candidate List */}
          <TableWrapper elevation={0} sx={{ tableLayout: 'auto', mb: '1.111vw', width: '100%' }}>
            <Box sx={{ overflowX: 'auto' }}>
              <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ fontWeight: '300', fontSize: '0.833vw', padding: '0.8vh 1.4vw', whiteSpace: 'nowrap', textAlign: 'start', color: theme.palette.text.secondary, minWidth: '6.944vw', maxWidth: '13.889vw' }}>Candidate Name</StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: '300', fontSize: '0.833vw', padding: '0.8vh 1.4vw', whiteSpace: 'nowrap', textAlign: 'start', color: theme.palette.text.secondary }}>Position</StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: '300', fontSize: '0.833vw', padding: '0.8vh 1.4vw', whiteSpace: 'nowrap', textAlign: 'start', color: theme.palette.text.secondary }}>Function</StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: '300', fontSize: '0.833vw', padding: '0.8vh 1.4vw', whiteSpace: 'nowrap', textAlign: 'start', color: theme.palette.text.secondary }}>Recruiter</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading?(
                    <TableRow>
                    <StyledTableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', padding: '20px' }}>
                    <CircularProgress disableShrink />
                    </StyledTableCell>
                </TableRow>
                  ):filteredData.length===0?(
                                <TableRow>
                                    <StyledTableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold', padding: '20px' }}>
                                        No candidates data available
                                    </StyledTableCell>
                                </TableRow>
                                ):(
                                 filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((candidate, index) => (
                    <TableRowStyled key={index} isEven={index % 2 === 0} onClick={()=>{handleRowClick(candidate)}}>
                      <StyledTableCell>
                        <Box display="flex" alignItems="center" sx={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <UserAvatar>{candidate.CandidateName[0]}</UserAvatar>
                          <Typography variant="body2" sx={{ pl: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {candidate.CandidateName}
                          </Typography>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body2">{candidate.CandidatePosition}</Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body2">{candidate.CandidateFunction}</Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body2">{candidate.CandidateRecruiter}</Typography>
                      </StyledTableCell>
                    </TableRowStyled>
                  ))
                                )}
                </TableBody>
              </Table>
            </Box>
  
            <Box sx={{ mt: '1.111vw', display: 'flex', justifyContent: 'center', position: 'relative', top: '-0.694vw' }}>
              <Pagination
                totalRows={filteredData.length}
                rowsPerPage={rowsPerPage}
                currentPage={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Box>
          </TableWrapper>
        </Box>
      </Container>
        
    );
};

export default CandidateDetails;
