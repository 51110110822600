import React, { useState, forwardRef, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  Slide,
  TextField,
  SelectChangeEvent
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { theme } from '../../../../../theme';
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import ReactQuill from 'react-quill'; // Import ReactQuill
import 'react-quill/dist/quill.snow.css'; // Import CSS for ReactQuill
import axios from 'axios';
import APIBaseURL from '../../../../../AppConfig/AppConfig.json'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// Slide transition component
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface emailTemplate {
  EmailNum: number;
  EmailSubject: string;
  EmailBody: string
}
interface NotifyCandidateProps {
  open: boolean;
  onClose: () => void;
  email: string | null;
  candidateStatus: string | null;
  handleSendNotify: (status: string | null,EmailSubject:string,EmailBody:string) => void;
  candidateName:string|null;
  recruiterName:string|null;
  candidatePosition:string | null
}

const NotifyCandidate: React.FC<NotifyCandidateProps> = ({ open, onClose, email, handleSendNotify, candidateStatus,candidateName,recruiterName,candidatePosition }) => {
  const [message, setMessage] = useState<string>('');
  const [status, setStatus] = useState<string | null>(candidateStatus);
  const { tenant } = useParams<{ tenant: string }>();
  const baseURL = window.location.origin.endsWith('/')
    ? window.location.origin
    : `${window.location.origin}/`;
  const navigate = useNavigate()
  const token = localStorage.getItem('access_token')
  const [emailData, setEmailData] = useState<emailTemplate[]>([])
  // const [initialMessage, setInitialMessage] = useState<string>('');
  // Sync status state with the candidateStatus prop when it changes
  useEffect(() => {
    setStatus(candidateStatus);
  }, [candidateStatus]);
  const [subject, setSubject] = useState<string>(''); // State for subject

  const handleSend = () => {
    // if (message !== initialMessage) { // Only send if message has changed
      handleSendNotify(status, subject, message);
      // onClose();
    // }
    onClose()
  }

  useEffect(() => {
    const fetchEmailTamplates = async () => {
      if (!token) {
        navigate(`/${tenant}/SignIn`)
        return
      }
      else {
        try {
          const response = await axios.get(`${APIBaseURL.apiBaseUrl}/communication/get-email-templates`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Tenant': tenant,
              'X-App': baseURL,
            }
          })
          if (response.status === 200) {
            setEmailData(response.data);

          // Check if the candidateStatus matches any EmailKey and set message/subject
          const matchedTemplate = response.data.find(
            (template: emailTemplate) => template.EmailSubject === candidateStatus
          );

          if (matchedTemplate) {
            setMessage(matchedTemplate.EmailBody.replace('@CandidateName',candidateName).replace('@Recruiter',recruiterName).replace('@JobTitle',candidatePosition));
            setSubject(`${matchedTemplate.EmailSubject} Notification`);
            // setInitialMessage(matchedTemplate.EmailBody);
          }
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
    if (open) {
      fetchEmailTamplates();
    }
    else{
      setStatus(candidateStatus)
      // setMessage('')
      // setStatus('')
    }
  }, [open,candidateStatus])

  // Handle change in status from dropdown
  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    const selectedStatus = event.target.value;
    setStatus(selectedStatus);

    // Find the template matching the selected status
    const matchedTemplate = emailData.find(
      (template) => template.EmailSubject === selectedStatus
    );

    if (matchedTemplate) {
      setMessage(matchedTemplate.EmailBody.replace('@CandidateName', candidateName ?? '') .replace('@Recruiter', recruiterName ?? '').replace('@JobTitle',candidatePosition ?? '')) ;
      setSubject(`${matchedTemplate.EmailSubject} Notification`);
      // setInitialMessage(matchedTemplate.EmailBody);
    } else {
      // If no template is found, set default message and subject
      setMessage("No template found for this status.");
      setSubject("Default Notification");
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      fullScreen
      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      PaperProps={{
        sx: {
          maxWidth: '20vw',
          height: '100vh',
          margin: 0,
          overflow: 'hidden',
          position: 'absolute',
          right: 0,
        },
      }}
    >
      {/* Header Area */}
      <DialogTitle sx={{ display: 'flex', p: '2vh 1.5vw 0px 1vw' }}>
        <Box
          color={theme.palette.secondary.main}
          sx={{
            border: '1px solid #EEEEEE',
            bgcolor: theme.palette.secondary.light,
            margin: '0.3vw',
            mr: '1vw',
            width: '3vw',
            height: '3vw',
            borderRadius: '1vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <i className="fa-regular fa-bell"></i>
        </Box>
        <Box>
          <Typography variant="h6">Notify Candidate</Typography>
          <Typography variant="body2">{candidateName}</Typography>
        </Box>
      </DialogTitle>

      <Divider sx={{ mb: '1.2vh', mt: '2vh' }} />

      {/* Content Area */}
      <DialogContent sx={{ overflowY: 'auto', pt: '0.56vw' }}>
        {/* Communication Medium */}
        <FormControl fullWidth margin="dense">
          <InputLabel size="small">Communication medium</InputLabel>
          <Select size="small" label="Communication medium" defaultValue="email">
            <MenuItem value="email">Email ({email})</MenuItem>
            {/* <MenuItem value="whatsapp">WhatsApp (+91-9992600995)</MenuItem> */}
          </Select>
        </FormControl>

        {/* Status Dropdown */}
        <FormControl fullWidth margin="dense" sx={{ mt: '2vh' }}>
          <InputLabel size="small">Status</InputLabel>
          <Select size="small" label="Status" value={status || ''} onChange={handleStatusChange}>
            <MenuItem value="Screening">Screening</MenuItem>
            <MenuItem value="Shortlisted">Shortlisted</MenuItem>
            <MenuItem value="HR Interview">HR Interview</MenuItem>
            <MenuItem value="Assessment">Assessment</MenuItem>
            <MenuItem value="Panel Interview">Panel Interview</MenuItem>
            <MenuItem value="Pre-boarding">Pre-boarding</MenuItem>
            <MenuItem value="On Hold">On Hold</MenuItem>
            <MenuItem value="Rejected">Rejected</MenuItem>
          </Select>
        </FormControl>

        {/* Subject */}
        <TextField
          label="Subject"
          size="small"
          value={subject} // Use value instead of defaultValue for controlled component
          onChange={(e) => setSubject(e.target.value)} // Update state on change
          fullWidth
          sx={{ marginY: '2vh' }}
        />

        {/* Message with ReactQuill */}
        <ReactQuill
          value={message}
          onChange={setMessage}
          style={{ height: '20vh', marginTop: '1vh' }}
          modules={{
            toolbar: [
              ['bold', 'italic', 'link', { 'list': 'ordered' }, { 'list': 'bullet' }],
            ],
          }}
        />

        <Divider variant="fullWidth" sx={{ mt: '7vh', mb: '1vh' }} />

        {/* Calendar Link */}
        <Typography variant="body2" color={theme.palette.text.secondary}>
          <InsertLinkRoundedIcon
            fontSize='small'
            sx={{
              transform: 'rotate(135deg)',  // Rotates the icon 45 degrees
              position: 'relative',
              top: '0.4vh',
              mr: '0.5vw'
            }}
          />
          http://calendar.laserbeam.com/ashgar
        </Typography>
        <Button variant='outlined' sx={{ my: '1vh' }} size='small'>
          <i className="fa-regular fa-copy" />
          <Typography ml={0.5} variant="body2">Copy link</Typography>
        </Button>
      </DialogContent>

      <Divider />

      {/* Footer Area */}
      <DialogActions sx={{ padding: '2vh' }}>
        <Button onClick={onClose} variant="outlined" fullWidth>
          Cancel
        </Button>
        <Button onClick={handleSend} variant="contained" fullWidth>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotifyCandidate;
