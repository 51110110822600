import React, { useState, useEffect } from 'react'; // Import useEffect
import {
  Box,
  Typography,
  ButtonGroup,
} from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom'; // Import useNavigate
import { StyledButton } from './Communication.styles';
import { useParams } from 'react-router-dom';

const Communication: React.FC = () => {
  const [emailList, setEmailList] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [isEmailConfigured, setIsEmailConfigured] = useState<boolean>(false);
  const { tenant } = useParams<{ tenant: string }>(); 
  const [selectedButton, setSelectedButton] = useState<string>('dashboard-messages'); // Track the selected button

  const navigate = useNavigate(); // Initialize navigate

  const handleSave = () => {
    if (emailList && message) {
      console.log('Message saved:', message);
      setIsEmailConfigured(true); // Show UserListTable after saving
    }
  };

  const handleNavigation = (path: string, buttonName: string) => {
    setSelectedButton(buttonName); // Update the selected button state
    navigate(path); // Navigate to the specified path
  };

  // Effect to navigate to the dashboard messages when component mounts
  useEffect(() => {
    handleNavigation(`/${tenant}/Communication/dashboard-messages`, 'dashboard-messages');
  }, []); // Empty dependency array means this runs once on mount

  return (
    <Box sx={{  position: 'relative',mr:'0.1vw',ml:' 0.9vw', width: '100%' ,pr:'0.78vw' }}>
      {/* Header and Actions */}
      <Box display="flex" justifyContent="space-between" gap={5} alignItems="center" sx={{ mb: 1.2 }}>
        <Typography gutterBottom variant="h5">Communication</Typography>
      </Box>

      <Box display="flex" justifyContent="space-between" sx={{ maxWidth: '30vw' }}>
        <ButtonGroup variant="outlined" aria-label="combined button group" sx={{ width: '100%' }}>
          <StyledButton
            variant="outlined"
            onClick={() => handleNavigation(`/${tenant}/Communication/dashboard-messages`, 'dashboard-messages')}
            sx={{
              mr: 0.13,
              flexGrow: 1, // Ensures equal space for each button
            }}
            isselected={selectedButton === 'dashboard-messages'} // Pass selected state
          >
            Dashboard Messages
          </StyledButton>

          <StyledButton
            variant="outlined"
            onClick={() => handleNavigation(`/${tenant}/Communication/email-templates`, 'email-templates')}
            sx={{
              flexGrow: 1, // Ensures equal space for each button
            }}
            isselected={selectedButton === 'email-templates'} // Pass selected state
          >
            Email Templates
          </StyledButton>
        </ButtonGroup>
      </Box>

  <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ width: '100%', mb: 1.6 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Communication;
