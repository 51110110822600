import * as React from 'react'; 
import { useState, useEffect } from 'react';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { useLocation, Link } from 'react-router-dom';
import {
  StyledListItemButtonParent,
  StyledListItemButtonChild,
  StyledListItemIcon,
  StyledListItemText,
  StyledAvatar,
  CompanyLogoContainer,
  StyledMainListItemText,
} from './Sidebar.styles';
import RElogo from '../../Assets/AuthBackGround/RecEzyLogo.png';
import { Box } from '@mui/material';
import { theme } from '../../theme';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Import jwt-decode
import { relative } from 'path';

export default function Sidebar() {
  const location = useLocation();
  const [open, setOpen] = useState<string | null>(null);
  const { tenant = 'defaultTenant' } = useParams<{ tenant: string }>();
  const [activeChildPath, setActiveChildPath] = useState<string>(`/${tenant}`);
  const [lastActiveParent, setLastActiveParent] = useState<string | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null); // State for storing user role
  const [isExpanded, setExpanded] = useState<boolean>(false)
  const [leaveTimeout, setLeaveTimeout] = useState<NodeJS.Timeout | null>(null);
  const [isCurrentPath ,setCurrentPath ] = useState<string | null>(null);

  const routeToParentMap: Record<string, string> = {
    [`/${tenant}/Joblisting`]: 'jobManagement',
    [`/${tenant}/EkoMatching`]: 'ekoRecruit',
    [`/${tenant}/CandidateProfile`]: 'candidateManagement',
    [`/${tenant}/CandidateExperience`]:'candidateManagement',
    [`/${tenant}/AssignTask`]:'candidateManagement',
    [`/${tenant}/ImportCandidates`]:'candidateManagement',
    [`/${tenant}/ImportNewJob`]: 'internalJobs',
    [`/${tenant}/CandidateDetails`]: 'preboarding',
    [`/${tenant}/Dashboard`]: 'insights',
    [`/${tenant}/Users`]: 'admin',
    [`/${tenant}/Communication`]: 'admin',
  };
  
  useEffect(() => {
    const currentPath = location.pathname.toLowerCase();
    let foundActiveChild = false;
  
    Object.entries(routeToParentMap).forEach(([path, section]) => {
      const normalizedPath = path.toLowerCase();
  
      if (currentPath.includes(normalizedPath)) {
        setActiveChildPath(normalizedPath);
        setOpen(section);
        setLastActiveParent(section); // Set last active parent on component load
        foundActiveChild = true;
      }
    });
    if (!foundActiveChild) {
      setOpen(`/${tenant}/jobManagement`);
      setActiveChildPath(`/${tenant}/Joblisting`);
      setLastActiveParent(`/${tenant}/jobManagement`);
    }
    try {
      const token = localStorage.getItem('access_token'); // Assuming JWT token is stored in localStorage
      if (token) {
        const decodedToken: any = jwtDecode(token); // Decode the token
        setUserRole(decodedToken.RoleKey); // Set the role from decoded token
      }
    } catch (error) {
      console.error("Error decoding JWT token:", error);
    }
  },[location.pathname]);

  // Handle the parent section click events
  const handleClick = (section: string) => {
    if (open === section) {
      setOpen(null); 
      setOpen(lastActiveParent); // Reopen the previously active parent if a different parent was closed
    } else {
      setLastActiveParent(open); // Update last active parent
      setOpen(section);          // Open the clicked parent
    }
  };
  

  // Update the active parent and child based on the location
  // useEffect(() => {
  //   const currentPath = location.pathname.toLowerCase();
  //   let foundActiveChild = false;
  
  //   Object.entries(routeToParentMap).forEach(([path, section]) => {
  //     const normalizedPath = path.toLowerCase();
  
  //     if (currentPath.includes(normalizedPath)) {
  //       setActiveChildPath(normalizedPath);
  //       setOpen(section);
  //       setLastActiveParent(section); // Set last active parent on component load
  //       foundActiveChild = true;
  //     }
  //   });
  
  //   if (!foundActiveChild) {
  //     setOpen('jobManagement');
  //     setActiveChildPath(`/${tenant}/Joblisting`);
  //     setLastActiveParent('jobManagement');
  //   }
  // }, [location.pathname]);
  
  // Function to handle mouse enter events
  const handleMouseEnter = () => {
    // Clear any previous timeout when the mouse enters
    if (leaveTimeout) {
      clearTimeout(leaveTimeout);
      setLeaveTimeout(null); // Reset the timeout
    }
    setExpanded(true); // Expand the sidebar
  };

  // Function to handle mouse leave events with a delay
  const handleMouseLeave = () => {
    if (open !== isCurrentPath) {
      setExpanded(false);
      // setOpen(isCurrentPath);
    } else {
      // Otherwise, set a delay to collapse the sidebar
      const timeout = setTimeout(() => {
        setExpanded(false); // Collapse the sidebar after the delay
      }, 500); // Delay of 500ms
      setLeaveTimeout(timeout); // Save the timeout to clear it later
    }
  };
  return (
    <div
    style={{
      position: 'absolute',
      left: '-1vw',
      paddingLeft: '1vw',
      transition: 'width 0.3s ease-in-out, padding-left 0.3s ease-in-out',
      width: isExpanded ? '100%' : '6vw',
      height: '100vh',
      backgroundColor: 'transparent',
      overflow: 'hidden',
      zIndex: 1000,
    }}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    onMouseOver={handleMouseEnter}
  >
    <List
 sx={{
  width: isExpanded
    ? {
      xs: '20vw',
      sm: '20vw',
      md: '20vw',
      lg: '20vw',
      xl: '18vw',
    }
    : '5vw',
  height: '100vh',
  maxWidth: '20vw',
  bgcolor: theme.palette.secondary.light,
  borderRight: '1px solid #E4E7EC',
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  position: 'fixed',
  transition: 'width 0.3s ease-in-out',
}}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {/* Group A: Company Logo */}
      <CompanyLogoContainer sx={{ bgcolor: 'transparent', paddingLeft: isExpanded ? '2vw' : '0.8vw', display: 'flex', alignItems: 'center', width: '100%' }}>
          <Box sx={{ width: '2.5vw', height: '2.5vw', ml: '0.5vw', mt: '0.2vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <StyledAvatar sx={{ width: '2.5vw', height: '2.5vw', ml: '0.5vw', mt: '0.2vh' }} src={RElogo} />
          </Box>
          {isExpanded && (
            <Typography
              variant="h5"
              sx={{                
                position: 'relative',
                top: '0.1vh',
                ml: 0,
                bgcolor:theme.palette.secondary.light,
                display: 'inline-block',
                overflow: 'hidden',
                whiteSpace: 'nowrap', // Prevents text from wrapping
                // width: '0', // Start with width of 0
                animation: 'typing 0.3s steps(10) 0.1s 0.5 normal both', // Typing animation with 0.3s duration
                // animationFillMode: 'forwards', // Keep the final state after animation
                visibility: 'visible', // Ensure the text is visible for typing animation
              }}
            >
              RecEzy
            </Typography>)}
        </CompanyLogoContainer>

 
      {/* Group B: Main Menu (Scrollable) */}
      <Box
        sx={{
          overflowY: 'auto',
          scrollbarWidth: 'thin',
        }}
      >
        {['jobManagement',
        //  'ekoRecruit', 
         'candidateManagement', 
        //  'internalJobs', 
         'preboarding', 
        //  'insights'
        ].map(section => (
          <React.Fragment key={section}>
             <StyledListItemButtonParent
                onClick={() => handleClick(section)}
                sx={{
                  '@media (max-width:940px)': {
                    width: isExpanded ? '90%' : '3.8vw',
                    height: isExpanded ? '5vh' : '3.2vw',
                    position:'relative',
                    left:'-2px',
                  },
                  '@media (min-width:940px)': {
                    width: isExpanded ? '90%' : '3.2vw',
                    height: isExpanded ? '5vh' : '3.2vw',
                  },
                  
                  '@media (min-width:1344px)': {
                    width: isExpanded ? '90%' : '3vw',
                    height: isExpanded ? '5vh' : '3vw',
                  },
                  backgroundColor: open === section ? theme.palette.primary.main : 'transparent',
                  color: open === section ? theme.palette.secondary.light : theme.palette.primary.main,
                  transition: 'width 0.3s ease-in-out, height 0.3s ease-in-out',
                  '& .MuiListItemText-primary': {
                    color: open === section ? theme.palette.secondary.light : theme.palette.primary.main,
                  },
                  '&:hover': {
                    backgroundColor: theme.palette.primary.main, // Ensure background changes on hover without affecting text
                    '& .MuiListItemText-primary': { color: '#fff', },
                    color:theme.palette.secondary.light,

                  },
                }}
              >
                {/* Icon Container */}
                <StyledListItemIcon
                  sx={{
                    color: 'inherit',
                    ":hover":{color:'#fff'},
                    transition: 'width 0.3s ease-in-out',
                  }}
                >
                  <i
                    style={{
                      position: 'relative',
                      paddingRight: '1vw',
                      left: isExpanded ? '-0.3vw' : '0vw',
                      fontSize: isExpanded ? '1.2rem' : '1.5rem',
                      
                      transition: 'left 0.3s ease, font-size 0.3s ease',
                    }}
                    className={getIconClass(section)}
                  />
                </StyledListItemIcon>

                {/* Text and Expand/Collapse Icon */}
                {isExpanded && (
                  <StyledMainListItemText
                    sx={{
                      color: theme.palette.primary.main,
                      
                      position: 'relative',
                      top: '0.1vh',
                    
                      ml: 0,
                      display: 'inline-block',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      width: 'fit-content',
                      animation: 'typing 4s steps(40) 1s 1 normal both',
                      animationFillMode: 'forwards',
                    }}
                    primary={getDisplayName(section)}
                  />
                )}
                {isExpanded && (
                  open === section ? (
                    <i style={{ position: 'relative', right: '0.5vw' }} className="fa-solid fa-chevron-down" />
                  ) : (
                    <i style={{ position: 'relative', right: '0.5vw' }} className="fa-solid fa-chevron-up" />
                  )
                )}
              </StyledListItemButtonParent>


              {isExpanded && <Collapse in={open === section} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {getChildItems(section,tenant).map(child => (
                    <Link
                      key={child.path}
                      to={child.path.toLowerCase()}
                      style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                    >
                      <StyledListItemButtonChild
                        sx={{
                          pl: '2vw',
                          transition: 'width 0.3s ease-in-out, height 0.3s ease-in-out',
                          backgroundColor: activeChildPath === child.path.toLowerCase() ? theme.palette.primary.light : 'transparent',
                          color: activeChildPath === child.path.toLowerCase() ? theme.palette.primary.main : theme.palette.primary.main, // Keep child text always visible with primary.main
                        }}
                      >
                        {isExpanded && (
                          <StyledListItemText
                            primary={child.name}
                            sx={{
                              backgroundColor: 'transparent',
                              color: activeChildPath === child.path.toLowerCase() ? theme.palette.primary.main : 'inherit', // Ensure child text color remains correct
                            }}
                          />
                        )}
                      </StyledListItemButtonChild>

                    </Link>
                  ))}
                </List>
              </Collapse>}
          </React.Fragment>
        ))}
        {(userRole === 'Super Admin' || userRole === 'Admin') && (
          <React.Fragment>
            <StyledListItemButtonParent
              onClick={() => handleClick('admin')}
              sx={{
                backgroundColor: open === 'admin' ? theme.palette.primary.main : 'transparent',
                color: open === 'admin' ? '#FFFFFF' : theme.palette.primary.main,
                '@media (max-width:1820px)': {
                  width: isExpanded ? '90%' : '3.2vw',
                  height: isExpanded ? '5vh' : '3.2vw',
                },
                
                '@media (min-width:1820px)': {
                  width: isExpanded ? '90%' : '2vw',
                  height: isExpanded ? '5vh' : '2vw',
                },
                  // backgroundColor: open === section ? theme.palette.primary.main : 'transparent',
                  // color: open === section ? theme.palette.secondary.light : theme.palette.primary.main,
                  transition: 'width 0.3s ease-in-out, height 0.3s ease-in-out',
                  '& .MuiListItemText-primary': {
                    color: open === 'admin' ? theme.palette.secondary.light : theme.palette.primary.main,
                  },
                  '&:hover': {
                    backgroundColor: theme.palette.primary.main, // Ensure background changes on hover without affecting text
                    '& .MuiListItemText-primary': { color: '#fff', },
                    color:theme.palette.secondary.light,

                  },
                
              }}
            >
              
              <StyledListItemIcon sx={{ color: 'inherit' }}>
                <i style={{   position: 'relative',
                      paddingRight: '1vw',
                      left: isExpanded ? '-0.3vw' : '0vw',
                      fontSize: isExpanded ? '1.2rem' : '1.5rem',
                      
                      transition: 'left 0.3s ease, font-size 0.3s ease', }} className="fa-solid fa-user-shield" />
              </StyledListItemIcon>
              {isExpanded && (  <StyledMainListItemText sx={{ color: 'inherit' }} primary="Admin" />)}
              {isExpanded && (
  open === 'admin' ? (
    <i style={{ position: 'relative', right: '0.5vw' }} className="fa-solid fa-chevron-down" />
  ) : (
    <i style={{ position: 'relative', right: '0.5vw' }} className="fa-solid fa-chevron-up" />
  )
)}

            </StyledListItemButtonParent>
            <Collapse in={open === 'admin'} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {getChildItems('admin', tenant).map(child => (
                  <Link
                    key={child.path}
                    to={child.path.toLowerCase()}
                    style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                  >
                    {isExpanded && (   <StyledListItemButtonChild
                      sx={{
                        pl: '2vw',
                        backgroundColor: activeChildPath === child.path.toLowerCase() ? '#F4EBFF' : 'transparent',
                        color: activeChildPath === child.path.toLowerCase() ? theme.palette.primary.main : '#000000',
                      }}
                    >
                      <StyledListItemText primary={child.name} />
                    </StyledListItemButtonChild>)}
                  </Link>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        )}
      </Box>
    </List>
    </div>
  );
}

// Helper Functions
const getIconClass = (section: string) => {
  const icons: Record<string, string> = {
    jobManagement: 'fa-solid fa-briefcase fa-fw',
    // ekoRecruit: 'fa-brands fa-uncharted fa-fw',
    candidateManagement: 'fa-solid fa-database fa-fw',
    // internalJobs: 'fa-solid fa-circle-nodes fa-fw',
    preboarding: 'fa-regular fa-clock fa-fw',
    // insights: 'fa-solid fa-chart-line fa-fw',
    admin: 'fa-solid fa-user-shield fa-fw',
  };
  return icons[section];
};

const getDisplayName = (section: string) => {
  const displayNames: Record<string, string> = {
    jobManagement: 'Job Management',
    // ekoRecruit: 'Eko Recruit',
    candidateManagement: 'Candidate Management',
    // internalJobs: 'Internal Jobs',
    preboarding: 'Pre-boarding',
    // insights: 'Insights',
    admin: 'Admin',
  };
  return displayNames[section];
};

const getChildItems = (section: string,tenant: string) => {
  if (!tenant) {
    // Handle the case where tenant is undefined, return an empty array or throw an error
    return [];
  }
  const childItems: Record<string, { path: string; name: string }[]> = {
    jobManagement: [
      { path: `/${tenant}/Joblisting`, name: 'Job Listing' },
      // { path: `/${tenant}/Askekotocreateajobposting`, name: 'Ask Eko to Create a Job Posting' },
      // { path: `/${tenant}/Importjobs`, name: 'Import Jobs' },
    ],
    // ekoRecruit: [{ path: `/${tenant}/EkoMatching`, name: 'Eko Matching' }],
    candidateManagement: [
      { path: `/${tenant}/CandidateProfile`, name: 'Candidate Profile' },
      { path: `/${tenant}/CandidateExperience`, name: 'Candidate Experience' },
      { path: `/${tenant}/AssignTask`, name: 'Assign Task' },
      { path: `/${tenant}/ImportCandidates`, name: 'Import Candidates' },
    ],
    // internalJobs: [{ path: `/${tenant}/ImportNewJob`, name: 'Import New Job' }],
    preboarding: [{ path: `/${tenant}/CandidateDetails`, name: 'Candidate Details' }],
    // insights: [
    //   { path: `/${tenant}/Dashboard`, name: 'Dashboard' },
    //   { path: `/${tenant}/Reports`, name: 'Reports' },
    // ],
    admin: [
      { path: `/${tenant}/Users`, name: 'Users' },
      { path: `/${tenant}/Communication`, name: 'Communication' },
    ],
  };
  return childItems[section] || []; 
};
