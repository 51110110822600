import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Avatar,
    Grid,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Button,
    CircularProgress,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import { green, red, orange, purple } from "@mui/material/colors";
import Pagination from "../../../Common/Pagination/pagination";
import {
    Container,
    TableRowStyled,
    ScreeningStatus,
    AssignmentStatus,
    StyledTableCell,
    TableWrapper,
    StyledTypography,
} from './CandidateContent.styles';
import StatusCard from "../../../Common/Card/Card";
import { theme } from "../../../../theme";
import FilterCandidates from "./FilterCandidates";
import NotesPopup from "../AddNotes/NotesPopup";
import NotifyCandidate from "../CandidateExperience/CandidateStatusAction/Notifycandidate";
import { useParams, useNavigate } from "react-router-dom";
import APIBaseURL from '../../../../AppConfig/AppConfig.json'
import axios from "axios";
import { UserAvatar } from "../AssignTask/AssignTask";
// Define Props
interface CandidateContentProps {
    showActionButton?: boolean; // Prop to control visibility of the action column
}


interface candidateData {
    MapID: number,
    CandidateNum: number,
    CandidateName: string,
    CandidateEmail: string,
    CandidatePosition: string,
    CandidateFunction: string,
    CandidateRecruiter: string,
    CandidateStatus: string,
    JobNum: number,
    DeletedItem: boolean,
    MatchedPct: number,
    DeletedCandidate:boolean
}



const CandidateContent: React.FC<CandidateContentProps> = ({ showActionButton }) => {
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [candidateList, setCandidateList] = useState<candidateData[]>([])
    const [filteredData, setFilteredData] = useState<candidateData[]>([]);
    const [isNotifyDialogOpen, setNotifyDialogOpen] = useState(false);
    const [isNotesDialogOpen, setNotesDialogOpen] = useState(false);
    const [selectedCandidateId, setSelectedCandidateId] = useState<number | null>(null); // State for selected candidate ID
    const [candidateStatus, setCandidateStatus] = useState<string | null>(null)
    const [openDocPopup, setOpenDocPopup] = useState(false);
    const [selectedDocUrl, setSelectedDocUrl] = useState<string>('');

    // const handlePageChange = (newPage: number) => setPage(newPage);
    const [candidateEmail, setCandidateEmail] = useState<string | null>(null)
    const [candidateName, setCandidateName] = useState<string | null>(null)
    const [recruiterName, setRecruiterName] = useState<string | null>(null)
    const [candidateJobNum, setCandidateJobNum] = useState<number | null>(null)
    const [candidateMapID, setCandidateMapID] = useState<number | null>(null)
    const [candidatePosition,setCandidatePosition]=useState<string | null>(null)
    const handleNotifyDialogOpen = (email: string, candidateNum: number, status: string, candidateName: string, recruiterName: string, jobnum: number, mapId: number,candidatePosition:string) => { setNotifyDialogOpen(true); setCandidateEmail(email); setSelectedCandidateId(candidateNum); setCandidateStatus(status); setCandidateName(candidateName); setRecruiterName(recruiterName); setCandidateJobNum(jobnum); setCandidateMapID(mapId);setCandidatePosition(candidatePosition) };
    const handleNotifyDialogClose = () => setNotifyDialogOpen(false);
    const [notes, setNotes] = useState<{ MapID: number, NoteValue: string; RecruiterName: string }[]>([])
    const { tenant } = useParams<{ tenant: string }>();
    const baseURL = window.location.origin.endsWith('/')
        ? window.location.origin
        : `${window.location.origin}/`;
    const navigate = useNavigate()
    const token = localStorage.getItem('access_token')
    const[loading,setLoading]=useState(true)
    const [activeFilter,setActiveFilter]=useState<string | null>(null)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // For delete confirmation dialog
    const [MapIdToDelete,setMapIdToDelete]=useState<number | null>(null)

    const fetchData = async () => {
        
        if (!token) {
            navigate(`/${tenant}/SignIn`)
            return
        }
        else {
            try {
                const response = await axios.get(`${APIBaseURL.apiBaseUrl}/job/get_candidates`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'X-Tenant': tenant,
                        'X-App': baseURL,
                    }
                })
                if (response.status === 200) {
                    const data: candidateData[] = Array.isArray(response.data) ? response.data : []; // Ensure it's an array
                    setCandidateList(data.filter((candidate)=>!candidate.DeletedCandidate))
                }
            } catch (error) {
                console.log(error)
            }
            finally {
                setLoading(false);
            }
        }
    }
    const fetchNoteData = async () => {
        if (!token) {
            navigate(`/${tenant}/SignIn`)
            return
        }
        else {
            try {
                const response = await axios.get(`${APIBaseURL.apiBaseUrl}/job/get_all_candidates_notes`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'X-Tenant': tenant,
                        'X-App': baseURL,
                    }
                })
                if (response.status === 200) {
                    setNotes(response.data)
                }
            } catch (error) {
                console.log(error)
            }
        }
    }
    useEffect(() => {
        fetchData()
        fetchNoteData()
    }, [])

    useEffect(() => {
        setFilteredData(candidateList.filter((candidate)=>!candidate.DeletedCandidate))
    }, [candidateList])

    const handleDeleteJob = (mapId: number) => {
        setMapIdToDelete(mapId); // Set job ID to delete
        setDeleteDialogOpen(true); // Open delete confirmation dialog
      };

    const confirmDeleteCandidate= async()=>{
        if(MapIdToDelete!==null){
            if(!token){
            navigate(`/${tenant}/SignIn`)
            return
            }
            try{
                const response=await axios.post(`${APIBaseURL.apiBaseUrl}/job/delete_candidate`,{
                    "MapID":MapIdToDelete
                },{
                    headers:{
                        Authorization: `Bearer ${token}`,
                        'X-Tenant': tenant,
                        'X-App': baseURL
                    }
                })
                if(response.status===200){
                    setCandidateList((prevData)=>prevData.filter((candidate)=>candidate.MapID!==MapIdToDelete))
                    setFilteredData((prevData)=>prevData.filter((candidate)=>candidate.MapID!==MapIdToDelete))
                }
            }catch(error){
                console.log(error)
            }
        }
        setDeleteDialogOpen(false); // Close dialog after deletion
        setMapIdToDelete(null); // Reset job ID to delete
    }

    const cancelDeleteJob = () => {
    setDeleteDialogOpen(false); // Close delete dialog without deleting
    setMapIdToDelete(null); // Reset job ID to delete
    }

    const handlePageChange = (newPage: number) => {
        const maxPages = Math.ceil(filteredData.length / rowsPerPage);
        if (newPage <= maxPages) {
            setPage(newPage);
        }
    };
    const handleNotesDialogOpen = (candidateId: number, status: string, recruiter: string, candidateName: string, mapId: number) => {
        setCandidateMapID(mapId)
        setCandidateName(candidateName)
        setRecruiterName(recruiter)
        setSelectedCandidateId(candidateId);
        setCandidateStatus(status)
        setNotesDialogOpen(true);
    };


    const [dialogOpenFilter, setDialogOpenFilter] = useState(false);
    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        setRowsPerPage(newRowsPerPage);
        setPage(1); // Reset to first page after changing rows per page
    };


    const statusOptions = [
        { value: 'Screening', label: 'Screening' },
        { value: 'Shortlisted', label: 'Shortlisted' },
        { value: 'HR Interview', label: 'HR Interview' },
        { value: 'Assessment', label: 'Assessment' },
        { value: 'Panel Interview', label: 'Panel Interview' },
        { value: 'Pre-boarding', label: 'Pre-boarding' },
        { value: 'On Hold', label: 'On Hold' },
        {value:'Rejected',label:'Rejected'}
    ];

    // Calculate pipeline stats based on filteredData
    const pipelineStats = [
        { title: "Active", count: candidateList.length, color: purple[500],filterOption:'Active' },
        { title: "Shortlisted", count: candidateList.filter(candidate => candidate.CandidateStatus === "Shortlisted").length, color: orange[500],filterOption:'Shortlisted' },
        { title: "Screening", count: candidateList.filter(candidate => candidate.CandidateStatus === "Screening").length, color: green[500],filterOption:'Screening' },
        { title: "Interview", count: candidateList.filter(candidate => candidate.CandidateStatus === "HR Interview" || candidate.CandidateStatus === "Panel Interview").length, color: red[500],filterOption:'Interview' },
        { title: "Offer letter", count: candidateList.filter(candidate => candidate.CandidateStatus === "Pre-boarding").length, color: red[500],filterOption:'Offer letter' },
    ];


    const handleOpenFilterDialog = () => {
        setDialogOpenFilter(true);
    };

    const handleCloseFilterDialog = () => {
        setDialogOpenFilter(false);
    };

    const applyFilters = (newFilters: any) => {
        let tempData = [...candidateList]
        if (newFilters.recruiter) {
            const recruitersArray = newFilters.recruiter.split(',').map((r: string) => r.trim().toLowerCase());
            tempData = tempData.filter(candidate => {
                const recruiter = candidate.CandidateRecruiter.toLowerCase();
                return recruitersArray.some((r: string) => recruiter.includes(r));
            });
        }

        if (newFilters.functionName) {
            const functionNameArray = newFilters.functionName.split(',').map((f: string) => f.trim().toLowerCase())
            tempData = tempData.filter(candidate => {
                const functionName = candidate.CandidateFunction.toLowerCase()
                return functionNameArray.some((f: string) => functionName.includes(f))
            })
        }

        if (newFilters.position) {
            console.log(newFilters.position)
            const positionArray = newFilters.position.split(',').map((p: string) => p.trim().toLowerCase())
            tempData = tempData.filter(candidate => {
                const position = candidate.CandidatePosition.toLowerCase()
                return positionArray.some((p: string) => position.includes(p))
            })
        }

        if (newFilters.sortByStatus && newFilters.sortByStatus.length > 0) {
            tempData = tempData.filter(candidate => newFilters.sortByStatus.includes(candidate.CandidateStatus));
        }

        if(newFilters.matchFilter && newFilters.matchFilter==='Matched'){
            tempData=tempData.filter(candidate=>candidate.MatchedPct>=50)
        }

        if(newFilters.matchFilter && newFilters.matchFilter==='Unmatched'){
            tempData=tempData.filter(candidate=>candidate.MatchedPct<50)
        }


        setFilteredData(tempData)
    }

    const handleApplyFilters = (newFilters: any) => {
        applyFilters(newFilters);
        handleCloseFilterDialog();
    }

    const handleFilterByCard = (value: string) => {
        setActiveFilter(value); // Set the active filter
        let filteredData = candidateList.filter((candidate) => {
            if (value === 'Shortlisted') {
                return candidate.CandidateStatus === 'Shortlisted';
            } else if (value === 'Screening') {
                return candidate.CandidateStatus === 'Screening';
            } else if (value === 'Interview') {
                return (
                    candidate.CandidateStatus === 'HR Interview' ||
                    candidate.CandidateStatus === 'Panel Interview'
                );
            } else if (value === 'Offer letter') {
                return candidate.CandidateStatus === 'Pre-boarding';
            }
            return true; // Default: include all candidates
        });
    
        setFilteredData(filteredData); // Update the filtered data in state
    };
    
    const handleClearFilterByCard=()=>{
        setActiveFilter(null); // Clear the active filter
        let tempData=[...candidateList]
        setFilteredData(tempData)
    }

    const handleSendNotify = async (status: string | null, EmailSubject: string, EmailBody: string) => {
        if (!token) {
            navigate(`/${tenant}/SignIn`)
            return
        }
        else {
            try {
                const response = await axios.post(`${APIBaseURL.apiBaseUrl}/job/send_notify`, {
                    "CandidateStatusKey": status,
                    "CandidateNum": selectedCandidateId,
                    "EmailSubject": EmailSubject,
                    "EmailBody": EmailBody,
                    "EmailID": candidateEmail,
                    "JobNum": candidateJobNum,
                    "CandidateName": candidateName,
                    "MapID": candidateMapID
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'X-Tenant': tenant,
                        'X-App': baseURL,
                    }
                })
                if (response.status === 200) {
                    setCandidateList(prevList => {
                        return prevList.map(candidate => {
                            if (candidate.CandidateNum === selectedCandidateId && candidate.JobNum === candidateJobNum) {
                                return {
                                    ...candidate,
                                    CandidateStatus: status ?? candidate.CandidateStatus, // Update the status
                                };
                            }
                            return candidate;
                        });
                    });
                }
            } catch (error) {
                console.log(error)
            }

        }
    }



    let filteredNotes = notes.filter((note) => note?.MapID === candidateMapID) as { MapID: number; NoteValue: string; RecruiterName: string }[];

    const handleSaveNote = async (MapID: number | null, noteValue: string | null) => {
        if (!MapID || noteValue === '') {
            return;
        }
        if (!token) {
            navigate(`/${tenant}/SignIn`)
            return
        }
        else {
            try {
                const response = await axios.post(`${APIBaseURL.apiBaseUrl}/job/add_candidate_note`, {
                    "MapID": MapID, "NoteValue": noteValue
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'X-Tenant': tenant,
                        'X-App': baseURL,
                    }
                })
                if (response.status === 200) {
                    // fetchNoteData()
                    const newNote = {
                        MapID: MapID ?? 0,
                        NoteValue: noteValue ?? '',
                        RecruiterName: recruiterName ?? '',
                    };

                    setNotes((prevNotes) => [
                        ...prevNotes,
                        newNote, // Add the new note
                    ]);


                }
            } catch (error) {
                console.log(error)
            }
        }
    }
    return (
        <Container sx={{ marginX: '1vw', '& .MuiBox-root': { padding: 0 } }}>
            <Box p={3} >
                <Typography variant="h5" sx={{ fontSize: '2.4vh', fontWeight: 'bold' }}>Hiring pipeline</Typography>
                <Typography variant="subtitle1" mb={2}>
                    See total numbers of people who covered each step of the process
                </Typography>

                {/* Pipeline Stats */}
                <Grid container spacing={2} sx={{ mb: 2, display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
                    {pipelineStats.map((stat, index) => (
                        <Grid item xs={4} key={index}>
                            <StatusCard title={stat.title} count={stat.count} filterOption={stat.filterOption} handleFilterByCard={handleFilterByCard} handleClearFilterByCard={handleClearFilterByCard} cardFilter={activeFilter===stat.filterOption}/>
                        </Grid>
                    ))}
                </Grid>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="h5" sx={{ fontSize: '2.4vh', fontWeight: 'bold' }}>Candidates list</Typography>
                    <Button variant="outlined" sx={{ color: theme.palette.primary.main, borderColor: theme.palette.primary.main }} onClick={handleOpenFilterDialog}>Filters & sort</Button>
                </Box>
                <FilterCandidates open={dialogOpenFilter} onClose={handleCloseFilterDialog} onApply={handleApplyFilters}
                    statusOptions={statusOptions}
                />

                {/* Candidate List */}
                <TableWrapper elevation={0} sx={{ tableLayout: 'auto', mb: 2 }}>
                    <Box sx={{ overflowX: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>


                                    <StyledTableCell sx={{ color: theme.palette.text.secondary, padding: '0.8vh 2.4vw', whiteSpace: 'nowrap' }}>
                                        <Typography variant="body3">Candidate Name</Typography>
                                    </StyledTableCell>

                                    <StyledTableCell sx={{ color: theme.palette.text.secondary, padding: '0.8vh 2.4vw', whiteSpace: 'nowrap' }}>
                                        <Typography variant="body3">Position</Typography>
                                    </StyledTableCell>

                                    <StyledTableCell sx={{ color: theme.palette.text.secondary, padding: '0.8vh 2.4vw', whiteSpace: 'nowrap' }}>
                                        <Typography variant="body3">Eko Matching</Typography>
                                    </StyledTableCell>

                                    <StyledTableCell sx={{ color: theme.palette.text.secondary, padding: '0.8vh 2.4vw', whiteSpace: 'nowrap' }}>
                                        <Typography variant="body3">Function</Typography>
                                    </StyledTableCell>

                                    <StyledTableCell sx={{ color: theme.palette.text.secondary, fontSize: '12px', padding: '0.8vh 2.4vw', whiteSpace: 'nowrap' }}>
                                        <Typography variant="body3">Recruiter</Typography>
                                    </StyledTableCell>

                                    <StyledTableCell sx={{ color: theme.palette.text.secondary, fontSize: '12px', padding: '0.8vh 2.4vw', whiteSpace: 'nowrap' }}>
                                        <Typography variant="body3">Notes</Typography>
                                    </StyledTableCell>

                                    {showActionButton && (
                                        <StyledTableCell sx={{ color: theme.palette.text.secondary, padding: '0.8vh 2.4vw', whiteSpace: 'nowrap' }}>
                                            <Typography variant="body3">Action</Typography>
                                        </StyledTableCell>
                                    )}

                                    <StyledTableCell sx={{ color: theme.palette.text.secondary, padding: '0.8vh 2.4vw', whiteSpace: 'nowrap' }}>
                                        <Typography variant="body3">Status</Typography>
                                    </StyledTableCell>

                                    {!showActionButton && (
                                        <StyledTableCell sx={{ color: theme.palette.text.secondary, padding: '0.8vh 2.4vw', whiteSpace: 'nowrap' }}>
                                            <Typography variant="body3">Action</Typography>
                                        </StyledTableCell>
                                    )}


                                    {/* <StyledTableCell sx={{ color: theme.palette.text.secondary, padding: '0.8vh 2.4vw', whiteSpace: 'nowrap' }}>
                                        <Typography variant="body3">Document</Typography>
                                    </StyledTableCell> */}

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading?(
                                    <TableRow>
                                    <StyledTableCell colSpan={showActionButton ? 8 : 7} sx={{ textAlign: 'center', fontWeight: 'bold', padding: '20px' }}>
                                     <CircularProgress disableShrink />
                                    </StyledTableCell>
                                </TableRow>
                                )
                                :filteredData.length === 0 ? (
                                    <TableRow>
                                        <StyledTableCell colSpan={showActionButton ? 8 : 7} sx={{ textAlign: 'center', fontWeight: 'bold', padding: '20px' }}>
                                            No candidates data available
                                        </StyledTableCell>
                                    </TableRow>
                                ) : (filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((candidate, index) => (
                                    <TableRowStyled key={index} isEven={index % 2 === 0}>


                                        <StyledTableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                            <Grid display="flex" alignItems="center" sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                                <UserAvatar>{candidate.CandidateName[0]}</UserAvatar>
                                                <Typography variant="body2" sx={{ pl: 1 }}>{candidate.CandidateName}</Typography>
                                            </Grid>
                                        </StyledTableCell>

                                        <StyledTableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                            <Typography variant="body2">{candidate.CandidatePosition || ''}</Typography>
                                        </StyledTableCell>

                                        <StyledTableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden', display:'flex' ,flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                                    <Typography
                                                        variant="body2"
                                                        fontWeight='bold'
                                                    >
                                                        {`${Math.round(candidate.MatchedPct || 0)}%`}
                                                        
                                                    </Typography>
                                                    {candidate.MatchedPct<50?<Typography color="error">Unmatched</Typography>:<Typography color="success">Matched</Typography>}
                                        </StyledTableCell>

                                        <StyledTableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                            <Typography variant="body2">{candidate.CandidateFunction || ''}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell >
                                            {candidate.CandidateRecruiter}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            <Button onClick={() => handleNotesDialogOpen(candidate.CandidateNum, candidate.CandidateStatus, candidate.CandidateRecruiter, candidate.CandidateName, candidate.MapID)}>
                                                View Notes
                                            </Button>
                                        </StyledTableCell>

                                        {showActionButton && (
                                            <StyledTableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    onClick={() => { handleNotifyDialogOpen(candidate.CandidateEmail, candidate.CandidateNum, candidate.CandidateStatus, candidate.CandidateName, candidate.CandidateRecruiter, candidate.JobNum, candidate.MapID,candidate.CandidatePosition) }}
                                                    sx={{ gap: 1, position: 'relative', left: -5 }}
                                                >
                                                    <i className="fa-solid fa-list-check"></i>
                                                    <Typography variant="body2">Action</Typography>
                                                </Button>

                                            </StyledTableCell>
                                        )}

                                        <StyledTableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                            {candidate.CandidateStatus === 'Screening' ? (
                                                <ScreeningStatus>• {candidate.CandidateStatus}</ScreeningStatus>
                                            ) : (
                                                <AssignmentStatus>• {candidate.CandidateStatus}</AssignmentStatus>
                                            )}
                                        </StyledTableCell>

                                        {!showActionButton && (
                                            <StyledTableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                            <IconButton onClick={()=>{handleDeleteJob(candidate.MapID)}} sx={{ color: theme.palette.primary.main }}>
                                                <i className="fa-solid fa-trash fa-xs"></i>
                                            </IconButton>
                                            </StyledTableCell>
                                        )}
                                        

                                        {/* <StyledTableCell sx={{ fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                            {candidate.documentUrl ? (
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    onClick={() => handleDocPopupOpen(candidate.documentUrl)}
                                                    sx={{ gap: 1 }}
                                                >
                                                    <i className="fa-solid fa-file"></i>
                                                    <Typography variant="body2" color="primary">View Resume</Typography>
                                                </Button>
                                            ) : (
                                                <Typography variant="body2">No Resume</Typography>
                                            )}
                                        </StyledTableCell> */}
                                    </TableRowStyled>
                                )))}
                            </TableBody>
                        </Table>
                    </Box>

                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', position: 'relative', top: '-0.694vw' }}>
                        <Pagination
                            totalRows={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            currentPage={page}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                        />
                    </Box>
                </TableWrapper>
            </Box>

            {/* Notify Candidates Dialog */}
            <NotifyCandidate
                open={isNotifyDialogOpen}
                handleSendNotify={handleSendNotify}
                email={candidateEmail}
                onClose={handleNotifyDialogClose}
                candidateStatus={candidateStatus}
                candidateName={candidateName}
                recruiterName={recruiterName} 
                candidatePosition={candidatePosition}/>

            {/* Notes Dialog */}
            <NotesPopup
                open={isNotesDialogOpen}
                onClose={() => { setNotesDialogOpen(false); }}
                userStatus={candidateStatus ?? ""}
                candidateNotes={filteredNotes}
                handleSave={handleSaveNote}
                candidateMapId={candidateMapID}
                candidateName={candidateName}
            />

            {/* Document Preview Dialog */}
            {/* <Dialog open={openDocPopup} onClose={handleDocPopupClose} fullWidth maxWidth="md">
                <DialogTitle>Document Preview</DialogTitle>
                <DialogContent>
                    <Box sx={{ width: '100%', height: '34.722vw', overflowY: 'auto' }}>
                        {selectedDocUrl.endsWith(".pdf") ? (
                            <iframe src={selectedDocUrl} width="100%" height="100%" title="Document Preview" />
                        ) : (
                            <img src={selectedDocUrl} alt="Document Preview" width="100%" />
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDocPopupClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog> */}

<Dialog
        open={deleteDialogOpen}
        onClose={cancelDeleteJob}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this candidate data? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDeleteJob} sx={{color:theme.palette.text.secondary}}>
            Cancel
          </Button>
          <Button onClick={confirmDeleteCandidate} sx={{ bgcolor: theme.palette.error.main }} variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
        </Container>
    );
};

export default CandidateContent;
