import React, { useState, useEffect, forwardRef } from 'react';
import {
  TextField, Button, Box, Grid, IconButton, Select, MenuItem, InputLabel, FormControl, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Divider,FormHelperText
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { TransitionProps } from '@mui/material/transitions';
import AvatarImage from '../../../../Navbar/AvatarImage';
import APIBaseURL from '../../../../../AppConfig/AppConfig.json'
import { validateEmail, validateUsername } from '../../../Validation/validation';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'; import { theme } from '../../../../../theme'; // Assuming the theme import is used globally

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface UserProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (updatedUser: any) => void; // Passes the updated user data back to the parent
  userData: {
    UserID: string;
    UserName: string;
    EmailID: string;
    UserRole: string;
    UserStatus: string;
    UserNum: number;
    photo: string,
    photoURL?: string, // URL for photo preview
  } | null;
}

const EditUser: React.FC<UserProps> = ({ open, onClose, onSubmit, userData }) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [formData, setFormData] = useState({
    userid: '',
    username: '',
    email: '',
    role: '',
    status: 'Active',
    usernum: 0,
    photo: null as File | null,
    photoURL: '', // URL for photo preview
  });

  useEffect(() => {
    if (userData) {
      setFormData({
        userid: userData.UserID || '',
        username: userData.UserName || '',  // Fallback to empty string
        email: userData.EmailID || '',
        role: userData.UserRole || '',
        status: userData.UserStatus || 'Active',  // Fallback to 'Active',
        usernum: userData.UserNum || 0,
        photo: null,
        photoURL: userData.photoURL || ''
      });
    } else {
      // Reset to default values if userData is null
      setFormData({
        userid: '',
        username: '',
        email: '',
        role: '',
        status: 'Active',
        usernum: 0,
        photo: null,
        photoURL: ''
      });
    }
  }, [userData]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileURL = URL.createObjectURL(file);
      setFormData({
        ...formData,
        photo: file,
        photoURL: fileURL,
      });
    }
  };
  const handleRoleChange = (event: SelectChangeEvent) => {
    setFormData({
      ...formData,
      role: event.target.value,
    });
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setFormData({
      ...formData,
      status: event.target.value,
    });
  };
  const handleAvatarClick = () => {
    const fileInput = document.getElementById('file-upload');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleSubmit = () => {
    const newErrors: { [key: string]: string } = {};
    if (!validateUsername(formData.username)) {
      newErrors.username =
        'Username can only contain letters and numbers.';
    }
    if (!validateEmail(formData.email)) {
      newErrors.email = 'Please enter a valid email address.';
    }
    if (!formData.role.trim()) {
      newErrors.role = 'Please select a role.';
    }
    if (!formData.status.trim()) {
      newErrors.status = 'Please select a status.';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return
    }
    onSubmit(formData);
    setFormData({
      userid: '',
      username: '',
      email: '',
      role: '',
      status: 'Active',
      usernum: 0,
      photo: null,
      photoURL: ''
    });
    onClose()
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      fullScreen

      PaperProps={{
        sx: {
          position: 'absolute',
          width: "20vw",
          right: 0,

        },
      }}
    >
      <DialogTitle sx={{ display: 'flex', p: '20px 15px 0px 10px' }}>
        <Box
          sx={{
            border: '1px solid #EEEEEE',
            bgcolor: theme.palette.secondary.light,
            color: theme.palette.secondary.main,
            margin: '6px',
            mr: '16px',
            width: '34px',
            height: '34px',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <i className="fa-solid fa-user-edit"></i>
        </Box>
        <Box>
          <Typography variant="h6">Edit User</Typography>
          <Typography variant="body2">Update the user information below</Typography>
        </Box>
      </DialogTitle>

      <Divider sx={{ mb: 1.2, mt: 2 }} />

      <DialogContent sx={{ pt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography gutterBottom variant='subtitle2'>Profile picture</Typography>
            <IconButton onClick={handleAvatarClick}>


              {formData.photoURL ? (
                <AvatarImage imageUrl={formData.photoURL} height="40px" width="40px" />
              ) : userData?.UserNum ? (
                <AvatarImage imageUrl={`${APIBaseURL.apiBaseUrl}/user/get_user_image/${userData.UserNum}`} height="40px" width="40px" />
              ) : (
                <AccountCircleOutlinedIcon sx={{ fontSize: '40px' }} />
              )}

            </IconButton>
            <Divider sx={{ mb: 1.2, mt: 2 }} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              <b>UserID: {formData.userid}</b>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              variant="outlined"
              error={!!errors.username}
              helperText={errors.username}
              autoComplete='off'
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              variant="outlined"
              error={!!errors.email}
              helperText={errors.email}
              autoComplete='off'
              required
            />
          </Grid>

        
          <Grid item xs={12}>
            <FormControl fullWidth size="small" required error={!!errors.role}>
              <InputLabel>Role</InputLabel>
              <Select value={formData.role} onChange={handleRoleChange} label="Role">
                <MenuItem value="HR">HR</MenuItem>
                <MenuItem value="Recruiter">Recruiter</MenuItem>
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="Super Admin">Super Admin</MenuItem>
              </Select>
              {errors.role && <FormHelperText>{errors.role}</FormHelperText>} 
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth size="small" required error={!!errors.status}>
              <InputLabel>Status</InputLabel>
              <Select value={formData.status} onChange={handleStatusChange} label="Status">
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="In-Active">In-Active</MenuItem>
              </Select>
              {errors.status && <FormHelperText>{errors.status}</FormHelperText>} 
            </FormControl>
          </Grid>
        </Grid>
        <input
          id="file-upload"
          type="file"
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleFileChange}
        />
      </DialogContent>

      <Divider />

      <DialogActions sx={{ display: 'flex', gap: 2, width: '100%', p: '8px 24px' }}>
        <Button onClick={onClose} variant="outlined" fullWidth >
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary" fullWidth >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUser;
