import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import Sidebar from '../../Navbar/Sidebar';
import { Outlet } from 'react-router-dom';
import TopNav from '../../Navbar/TopNav'; // Import TopNav
 
const Dashboard: React.FC = () => {
  const token=localStorage.getItem('access_token')
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
      }}
    >
      <CssBaseline />
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        {/* Sidebar with fixed width */}
        {token && (
         <Box sx={{         width:'5vw', position: 'relative', flexShrink: 0 }}>
            <Sidebar />
          </Box>
        )}
 
        {/* Main content takes the rest of the available width */}
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh',width:'95vw' }}>
        {token && <TopNav />}
        <Box
sx={{
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'flex-start',
  padding: '1vw',
  pr: '0.28vw',
  // position:'relative',
  // top:'-2vw',
  pt:0,
  overflowY: 'auto', // Ensures a scrollbar appears only when needed
  '@media (max-width:600px)': {
    padding: 1, // Adjust padding for smaller screens
    pt:0
  },
}}
>
  <Outlet />
</Box>
    </Box>
    </Box>
    </Box>
  );
};
 
export default Dashboard;