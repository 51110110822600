import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import MarketData from './MarketData/MarketData';
import SimilarJobPostings from './SimilarJobPostings/SimilarJobPosting';
import CreateJob from './CreateJob/CreateJob';
import { useParams,useNavigate } from 'react-router-dom';
import APIBaseURL from '../../../../AppConfig/AppConfig.json'
import axios from 'axios';


interface marketSalaryDataType{
    MarketDataNum:number|null,
    JobNum:number|null,
    JobTitle:string|null,
    MaximumSalary:number|null,
    MedianSalary:number|null,
    MinimumSalary:number|null
}

interface similarJobsDataType{
    CompanyName:string|null,
    JobTitle:string|null,
    Region:string|null,
    SimilarJobNum:number|null,
    JobLink:string
}
const CreateJobPosting: React.FC = () => {
    const [jobNum,setJobNum]=useState<number>()
    const[readOnly,setReadOnly]=useState(false)
    const[marketJobTitle,setMarketJobTitle]=useState<string>('')
    const[marketSalaryData,setMarketSalaryData]=useState<marketSalaryDataType|null>(null)
    const[similarJobsData,setSimilarJobsData]=useState<similarJobsDataType[]>([])
    
    const[message,setMessage]=useState(false)
    const token=localStorage.getItem('access_token')
    const navigate = useNavigate()
    const { tenant } = useParams<{ tenant: string }>();
    const baseURL = window.location.origin.endsWith('/')
        ? window.location.origin
        : `${window.location.origin}/`;
    const [loading,setLoading]=useState(false)

    const fetchMarketJobData=async(jobNum:number)=>{
        if (!token) {
            navigate(`/${tenant}/SignIn`);
            return;
        }
        try{
            const response=await axios.get(`${APIBaseURL.apiBaseUrl}/job/get_market_jobdata/${jobNum}`,{
                headers:{
                    Authorization: `Bearer ${token}`,
                    'X-Tenant': tenant,
                    'X-App': baseURL
                }
            })
            if(response.status===200){
                setMarketSalaryData(response.data.market_salary_data|| null)
                setSimilarJobsData(response.data.similar_jobs_data|| [])
            }
        }catch(error){
            console.error('Error fetching market job data:', error)
        }
    }

    useEffect(()=>{
        if(jobNum){
            fetchMarketJobData(jobNum)
        }
    },[jobNum])

    useEffect(() => {
        const interval = setInterval(() => {
            // Only fetch data if marketSalaryData and similarJobsData are empty
            if (!marketSalaryData && similarJobsData.length === 0) {
                if (jobNum) {
                    fetchMarketJobData(jobNum);
                }
            }
        }, 60000); // 60000 milliseconds = 1 minute

        return () => {
            clearInterval(interval); // Cleanup on unmount
        };
    }, [jobNum]); // Only depend on jobNum

 
    const runPostMarketJobData=async(jobNum:number,jobTitle:string)=>{
        try{
            const response=await axios.post(
                `${APIBaseURL.apiBaseUrl}/job/post_market_jobdata`,
                {"JobNum":jobNum,"JobTitle":jobTitle},
                {
                    headers:{
                    Authorization: `Bearer ${token}`,
                    'X-Tenant': tenant,
                    'X-App': baseURL
                    }
                }
            )
        }catch(error){
            console.log(error)
        }
    }

  
   
   
    const handleUptoDateMarketData=async()=>{
        if (!token) {
            navigate(`/${tenant}/SignIn`);
            return;
        }
        if( jobNum ){
            setLoading(true)
            try{
                const response=await axios.post(`${APIBaseURL.apiBaseUrl}/job/get_uptodate_market_jobdata`,{
                    "JobNum":jobNum
                },{
                    headers:{
                        Authorization: `Bearer ${token}`,
                        'X-Tenant': tenant,
                        'X-App': baseURL
                    }
                })
                if(response.status===200){
                    setMarketSalaryData(response.data.market_salary_data|| null)
                    setSimilarJobsData(response.data.similar_jobs_data|| [])
                    setMessage(true)
                    setLoading(false)
                }
            }catch(error){
                setLoading(false)
                console.error('Error fetching upto date market job data:', error)
            }
        }
        
    }
    return (
        <Box 
            sx={{  
                ml:'1vw',
                mr:'0.2vw',
                width:'100%',              
                boxSizing: 'border-box', // Ensure padding does not affect width
                display: 'flex',
                pr:0,
                flexDirection: 'column',
                gap: 1,
            }}
        >
            <Box > 
                <CreateJob setJobNum={setJobNum} setMarketJobTitle={setMarketJobTitle} setReadOnly={setReadOnly} runPostMarketJobData={runPostMarketJobData} />
            </Box>

            {/* {jobNum?(marketSalaryData || similarJobsData.length>0)?<></>:<Box sx={{display:'flex',justifyContent:'center'}}><Typography variant='subtitle1' sx={{display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid #E0E0E0',px:'1vw',py:'0.5vw',gap:1,borderRadius:'0.5vw'}}><CircularProgress size={30} disableShrink/>Market data is fetching</Typography></Box>:<></>} */}
            
            <Box > 
                {jobNum?(<MarketData 
                marketSalaryData={marketSalaryData} 
                handleUptoDateMarketData={handleUptoDateMarketData} 
                message={message} loading={loading} 
                marketJobTitle={marketJobTitle} 
                readOnly={readOnly}/>):(<></>)}
            </Box>
            <Box>
                {jobNum?(<SimilarJobPostings 
                similarJobsData={similarJobsData} 
                />):(<></>)}
            </Box>
        </Box>
    );
};

export default CreateJobPosting;
