import React, { useState, ChangeEvent, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Grid, Box, FormControlLabel, Typography, Snackbar, Alert,CircularProgress } from '@mui/material';
import {
  ImageContainer, FormContainer, StyledButton, StyledTextField, StyledDivider,
  Heading, IconContainer, GridContainer, TermsText, CheckboxStyled
} from './SignIn.styles';
import BackGround from '../../../Assets/AuthBackGround/AuthBG.png';
import LinkedIn from "../../../Assets/AuthBackGround/LinkedIn.png";
import Facebook from "../../../Assets/AuthBackGround/facebook.png";
import GLogo from "../../../Assets/AuthBackGround/GLogo1.png";
import axios from 'axios';
import APIBaseURL from '../../../AppConfig/AppConfig.json'
import { useParams } from 'react-router-dom';

import { theme } from '../../../theme';


interface FormValues {
  userid: string;
  password: string;
}

const SignIn: React.FC = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    userid: '',
    password: '',
  });
  const [errors,setErrors]=useState<{ [key: string]: string }>({})
  const [catchErrors, setCatchErrors] = useState<string[]>([]); 
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [loading,setLoading]=useState(false)

  // Snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | undefined>(undefined);
  const [snackbarWidth, setSnackbarWidth] = useState<number>(0);
  const snackbarRef = useRef<HTMLDivElement | null>(null);

  const location = useLocation();

  // Extract the success message from location state (passed from ForgotPassword)
  useEffect(() => {
    if (location.state && location.state.snackbarMessage) {
      setSnackbarMessage(location.state.snackbarMessage);
      setSnackbarSeverity(location.state.snackbarSeverity);
      setSnackbarOpen(true);
    }
  }, [location.state]);

  const { tenant } = useParams<{ tenant: string }>();
  const baseURL = window.location.origin.endsWith('/')
    ? window.location.origin
    : `${window.location.origin}/`;

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      navigate(`/${tenant}/Joblisting`);
    }
  }, [navigate]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleSubmit = async () => {
    const newErrors: { [key: string]: string } = {};
    if (formValues.userid.trim().length === 0) {
      newErrors.userid ='UserId is required.';
    }

    if (formValues.password.trim().length === 0) {
      newErrors.password ='Password is required.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return
  }
  setLoading(true)
    try {
      const response = await axios.post(`${APIBaseURL.apiBaseUrl}/account/get_token`, {
        "userId": formValues.userid, "password": formValues.password
      }, {
        headers: {
          'X-Tenant': tenant,
          'X-App': baseURL
        }
      })
      if (response.status === 200) {
        setLoading(false)
        const access_token = response.data
        localStorage.setItem('access_token', access_token);
        navigate(`/${tenant}/Joblisting`); // Navigate to the dashboard page
      }
    } catch (error: any) {
      setLoading(false)
      const newCatchErrors: string[] = [];
      if (error.response) {
        switch (error.response.status) {
          case 401:
            newCatchErrors.push('Invalid username or password.');
            break;
          case 404:
            newCatchErrors.push('Tenant not found.');
            break;
          default:
            newCatchErrors.push('An error occurred. Please try again.');
            break;
        }
      } else {
        // Network error or no response
        newCatchErrors.push('Network error or no response.');
      }
      setCatchErrors(newCatchErrors);
    }
    
  };
  // Handle closing the Snackbar
  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
        return;
    }
    setSnackbarOpen(false);
};
   // Handle key press event for Enter key
   const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (snackbarRef.current) {
      setSnackbarWidth(snackbarRef.current.offsetWidth);
    }
  }, [snackbarOpen]);

  return (
    <Grid container className="sign-in-container" sx={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
      {/* Left side with image */}
      <Grid item xs={12} md={6} className="image-grid-item" sx={{ width: '50vw', height: '100vh' }}>
        <ImageContainer
          sx={{
            backgroundImage: `url(${BackGround})`,
            height: '100vh',
            width: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          className="image-container"
        />
      </Grid>

      {/* Right side with form */}
      <Grid item xs={12} md={6} className="form-grid-item" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <FormContainer className="form-container" sx={{ width: '40vw', padding: '2vh 2vw' }}>
          <Box className="group-one" sx={{ width: '100%', marginBottom: '3vh' }}>
            <Typography variant="h4" className="heading" >
              Sign In
            </Typography>
            <Typography variant="subtitle1" className="existing-account-text" sx={{marginBottom: '2vh' }}>
              Sign in with an existing account
            </Typography>
          </Box>

          <Box className="group-two" sx={{ width: '100%' }}>
            {/* <IconContainer className="icon-container" sx={{ position: 'relative', marginBottom: '2vh' }}>
              <img src={LinkedIn} alt="LinkedIn Logo" width="42" height="42" className="social-icon" />
              <img src={Facebook} alt="Facebook Logo" width="42" height="42" className="social-icon" />
              <img src={GLogo} alt="Google Logo" width="42" height="42" className="social-icon" />
            </IconContainer> */}
            <GridContainer container spacing={3} className="grid-container" sx={{ pl:3,alignItems: 'center' }}>
            {/* <StyledDivider className="styled-divider" sx={{ fontSize: '1.5vh', marginBottom: '2vh' }}>
              <Typography variant='body2'> or continue with Email</Typography>
            </StyledDivider> */}


                <StyledTextField
                  label="UserId"
                  name="userid"
                  value={formValues.userid}
                  onChange={handleChange}
                  fullWidth
                  required
                  size="small"
                  className="styled-text-field"
                  error={!!errors.userid}
                  helperText={errors.userid}
                  autoComplete='off'
                  sx={{ fontSize: '1.8vh',mt:2 }}
                  onKeyDown={handleKeyPress}  
                  disabled={loading}
                />





                <StyledTextField
                  label="Password"
                  type="password"
                  name="password"
                  value={formValues.password}
                  onChange={handleChange}
                  fullWidth
                  required
                  size="small"
                  className="styled-text-field"
                  error={!!errors.password}
                  helperText={errors.password}
                  autoComplete='off'
                  sx={{ fontSize: '1.8vh' }}
                  onKeyDown={handleKeyPress}  
                  disabled={loading}
                />


            {/* Remember me and forgot password */}
            <GridContainer container spacing={2} className="grid-container" sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', paddingBottom: '1rem', margin: '10px 0 10px 0' }}>
              {/* <FormControlLabel
                control={<CheckboxStyled checked={rememberMe} onChange={handleRememberMeChange} size="small" />}
                label={<TermsText variant="body2" color={theme.palette.text.secondary} pr={2} className="terms-text" sx={{ fontSize: '1.5vh' }}>Remember me</TermsText>}
                sx={{ marginLeft: '0.1vw' }}
              /> */}
              <Link to={{ pathname: `/${tenant}/forgot-password`, }} state={{ userId: formValues.userid }} style={{ textDecoration: 'none' }}>
                <Typography variant='body2' color='primary' mr={2} > Forgot password?</Typography>
              </Link>
            </GridContainer>
              
            {catchErrors.length > 0 && (
            <Box sx={{ marginBottom: '20px', color: 'red' }}>
              {catchErrors.map((error, index) => (
                <Typography key={index} variant="body2">{error}</Typography>
              ))}
            </Box>
          )}
          
                <StyledButton
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="small"
                  className="styled-button"
                  sx={{ fontSize: '2vh', marginBottom: '1vh' }}
                  disabled={loading}
                >
                  {loading?<CircularProgress size="30px" />:"Sign In"}
                </StyledButton>
        
            </GridContainer>

            {/* Checkbox for terms and conditions */}
            {/* <Grid container spacing={2} alignItems="center" my={2}>
              <Grid item sx={{ml:'0.2vw'}}>
                <CheckboxStyled size="small" />
              </Grid>
              <Grid item>
                <Typography variant="body2" sx={{ fontSize: '1.5vh',position:'relative',left:'-0.5vw' }}>
                  By continuing, you accept our standard <Link to="/terms" style={{ textDecoration: 'none', color: '#3f51b5' }}>terms and conditions</Link> and <Link to="/privacy" style={{ textDecoration: 'none', color: '#3f51b5' }}>privacy policy</Link>.
                </Typography>
              </Grid>
            </Grid> */}
          </Box>

          {/* <Box mt={2} textAlign="left" className="sign-up-container">
            <Typography variant="body2" className="sign-up-text" sx={{ fontSize: '1.8vh' }}>
              Don't have an account?{' '}
              <Link to={`/${tenant}/SignUp`} className="sign-up-link" style={{ fontSize: '1.8vh',textDecoration:'none' }}>Sign Up</Link>
            </Typography>
          </Box> */}
        </FormContainer>
      </Grid>

      {/* Snackbar for messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          ref={snackbarRef}
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          icon={false}
          sx={{
            position: 'fixed',
            top: '2vh',
            right: `calc(50vw - ${snackbarWidth / 2}px)`,
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default SignIn;
