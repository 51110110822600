import { styled } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';

// Styles for Parent Menu (With Icon)
export const StyledListItemButtonParent = styled(ListItemButton)(({ theme }) => ({
  width: '90%',
  height: '5vh',
  padding: '1.5vh 0vh  1.5vh 0.8vw',
  marginLeft: '1vw',
  borderRadius: '0.5vw',
  marginBottom: '1vh',
  backgroundColor: 'transparent',
  transition: 'none',
  animation: 'none',
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
  },
}));

export const StyledMainListItemText = styled(ListItemText)(({ theme }) => ({
  margin: 0,
  paddingLeft:0,
  color: theme.palette.primary.main,
  position:'relative',
  left:'-0.8vw',
  '& .MuiListItemText-primary': {
    fontSize: '1vw',
    fontWeight: '700',
    lineHeight: '3vh',
    textAlign: 'left',
    paddingRight: '0.5vw',

    
  },
}));

// Styles for Child Menu (Without Icon)
export const StyledListItemButtonChild = styled(ListItemButton)(({ theme }) => ({
  width: '90%',
  height: '5vh',
  padding: '0.5vh 0',
  borderRadius: '0.5vw',
  transition: 'none',
  marginLeft: '1vw',
  marginBottom: '1.5vh',
  animation: 'none',
  backgroundColor: 'transparent',
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: '#F4EBFF',
    color: theme.palette.primary.main,
  },
  '&:focus': {
    backgroundColor: '#F4EBFF',
    outline: 'none',
    color: theme.palette.primary.main,
  },
}));

// Shared styles for ListItemIcon
export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '2vw',
  fontSize:'1vw',
  color: theme.palette.primary.main,
}));

// Shared styles for ListItemText
export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  margin: 0,
  color: theme.palette.primary.main,
  fontFamily: 'Inter',
  position:'relative',
  left:'-0.9vw',
  '& .MuiListItemText-primary': {
    fontSize: '0.9vw',
    fontWeight: '800',
    lineHeight: '2vh',
    padding: '0.5vh 0.8vw',
    whiteSpace: 'nowrap',
  },
}));

// Styles for Avatar
export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: '2vw',
  height: '2vw',
}));

// Styles for IconButton (Exit Button)
export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: '2vw',
  height: '2vw',
  color: '#7F56D9',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
  },
  position: 'absolute',
  right: '1vw',
}));

// Styles for Company Logo Container
export const CompanyLogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  marginBottom: '3vh',
  paddingLeft: '1vw',
}));
