import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import Pagination from '../../../Common/Pagination/pagination';
import { theme } from '../../../../theme';
import FilterCandidates from '../CandidateContent/FilterCandidates';
import AssignTaskPopup from './AssignTaskPopup';
import NotesPopup from '../AddNotes/NotesPopup'; // Import NotesPopup
import { useParams, useNavigate } from 'react-router-dom';
import APIBaseURL from '../../../../AppConfig/AppConfig.json'
import axios from 'axios';
// Add Task Button
const AssignButton = styled(Button)({
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
});

interface candidateData {
  MapID: number,
  CandidateNum: number,
  CandidateName: string,
  CandidateEmail: string,
  CandidatePosition: string,
  CandidateFunction: string,
  CandidateRecruiter: string,
  CandidateStatus: string,
  DeletedItem: boolean,
  MatchedPct: number,
  DeletedCandidate:boolean
}
// User Avatar placeholder
export const UserAvatar = styled('div')({
  width: '2.1vw',
  height: '2.1vw',
  borderRadius: '50%',
  backgroundColor: '#1976d2',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '0.6vw',
});

// Active and Inactive Status styles
const ActiveStatus = styled('span')({
  color: '#067647',
  border: '0.07vw solid #47CD89',
  background: '#ECFDF3',
  padding: '0.3vw 0.97vw',
  borderRadius: '50vw',
  fontWeight: 500,
});

const InactiveStatus = styled('span')({
  color: '#B54708',
  border: '0.07vw solid #FECDCA',
  background: '#FEF3F2',
  padding: '0.3vw 0.83vw',
  borderRadius: '50vw',
  fontWeight: 500,
});

const TableWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '1.11vw',
  overflow: 'hidden',
  boxShadow: 'none',
  border: `0.07vw solid ${theme.palette.divider}`,
  overflowX: 'auto',
}));

const AssignTask: React.FC = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [candidates, setCandidates] = useState<candidateData[]>([]);
  const [filteredData, setFilteredData] = useState<candidateData[]>([]);
  const [dialogOpenFilter, setDialogOpenFilter] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [notesPopupOpen, setNotesPopupOpen] = useState(false); // State for NotesPopup
  const [selectedCandidateId, setSelectedCandidateId] = useState<number | null>(null);
  const [recruiterName, setRecruiterName] = useState<string | null>(null)
  const [candidateStatus, setCandidateStatus] = useState<string | null>(null) // State for selected candidate ID
  const [candidateName, setCandidateName] = useState<string | null>(null)
  const [notes, setNotes] = useState<{ MapID: number, NoteValue: string; RecruiterName: string }[]>([])
  const { tenant } = useParams<{ tenant: string }>();
  const baseURL = window.location.origin.endsWith('/')
    ? window.location.origin
    : `${window.location.origin}/`;
  const navigate = useNavigate()
  const token = localStorage.getItem('access_token')
  const [candidateMapID, setCandidateMapID] = useState<number | null>(null)
  const candidate_access_token = localStorage.getItem('access_token')
  const[loading,setLoading]=useState(true)

  useEffect(() => {
    const fetchData = async () => {
      if (!token && !candidate_access_token) {
        navigate(`/${tenant}/AccessDenied`)
        return
      }
      if (!token) {
        navigate(`/${tenant}/SignIn`)
        return
      }
      else {
        try {
          const response = await axios.get(`${APIBaseURL.apiBaseUrl}/job/get_candidates`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Tenant': tenant,
              'X-App': baseURL,
            }
          })
          if (response.status === 200) {
            const data: candidateData[] = Array.isArray(response.data) ? response.data : []; // Ensure it's an array
            setCandidates(data.filter((candidate)=>!candidate.DeletedCandidate))
          }
        } catch (error) {
          console.log(error)
        }
        finally {
          setLoading(false);
      }
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    setFilteredData(candidates.filter((candidate)=>!candidate.DeletedCandidate))
  }, [candidates])

  const fetchNoteData = async () => {
    if (!token) {
      navigate(`/${tenant}/SignIn`)
      return
    }
    else {
      try {
        const response = await axios.get(`${APIBaseURL.apiBaseUrl}/job/get_all_candidates_notes`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Tenant': tenant,
            'X-App': baseURL,
          }
        })
        if (response.status === 200) {
          setNotes(response.data)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    fetchNoteData()
  }, [])

  let filteredNotes = notes.filter((note) => note?.MapID === candidateMapID) as { MapID: number; NoteValue: string; RecruiterName: string }[];

  const handleSaveNote = async (MapID: number | null, noteValue: string | null) => {
    if (!token) {
      navigate(`/${tenant}/SignIn`)
      return
    }
    else {
      try {
        const response = await axios.post(`${APIBaseURL.apiBaseUrl}/job/add_candidate_note`, {
          "MapID": MapID, "NoteValue": noteValue
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Tenant': tenant,
            'X-App': baseURL,
          }
        })
        if (response.status === 200) {
          // fetchNoteData()
          const newNote = {
            MapID: MapID ?? 0,
            NoteValue: noteValue ?? '',
            RecruiterName: recruiterName ?? '',
          };

          setNotes((prevNotes) => [
            ...prevNotes,
            newNote, // Add the new note
          ]);

        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleSelect = (id: number) => {
    const newSelected = new Set(selected);
    if (newSelected.has(id)) {
      newSelected.delete(id);
    } else {
      newSelected.add(id);
    }
    setSelected(newSelected);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelected(new Set());
    } else {
      const allCandidateIds = new Set(candidates.map(candidate => candidate.CandidateNum));
      setSelected(allCandidateIds);
    }
    setSelectAll(!selectAll);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };

  // Function to open NotesPopup with the selected candidate ID
  const openNotesPopup = (id: number, status: string, recruiter: string, candidateName: string, mapId: number) => {
    setCandidateName(candidateName)
    setSelectedCandidateId(id);
    setCandidateStatus(status);
    setRecruiterName(recruiter)
    setCandidateMapID(mapId)
    setNotesPopupOpen(true);
  };

  const handleOpenFilterDialog = () => {
    setDialogOpenFilter(true);
  };

  const handleCloseFilterDialog = () => {
    setDialogOpenFilter(false);
  };
  const handleApply = (newFilters: any) => {
    applyFilters(newFilters);
    handleCloseFilterDialog();
  }
  const applyFilters = (newFilters: any) => {
    let tempData = [...candidates]
    if (newFilters.recruiter) {
      const recruitersArray = newFilters.recruiter.split(',').map((r: string) => r.trim().toLowerCase());
      tempData = tempData.filter(candidate => {
        const recruiter = candidate.CandidateRecruiter.toLowerCase();
        return recruitersArray.some((r: string) => recruiter.includes(r));
      });
    }

    if (newFilters.functionName) {
      const functionNameArray = newFilters.functionName.split(',').map((f: string) => f.trim().toLowerCase())
      tempData = tempData.filter(candidate => {
        const functionName = candidate.CandidateFunction.toLowerCase()
        return functionNameArray.some((f: string) => functionName.includes(f))
      })
    }

    if (newFilters.position) {
      const positionArray = newFilters.position.split(',').map((p: string) => p.trim().toLowerCase())
      tempData = tempData.filter(candidate => {
        const position = candidate.CandidatePosition.toLowerCase()
        return positionArray.some((p: string) => position.includes(p))
      })
    }
    if(newFilters.matchFilter && newFilters.matchFilter==='Matched'){
      tempData=tempData.filter(candidate=>candidate.MatchedPct>=50)
  }

  if(newFilters.matchFilter && newFilters.matchFilter==='Unmatched'){
      tempData=tempData.filter(candidate=>candidate.MatchedPct<50)
  }

    setFilteredData(tempData)
  }
  return (
    <Box sx={{ width: '100%', mx: '1vw' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '1.39vh' }}>
        <Box mb='1.39vh'>
          <Typography variant="h5">My Open Jobs</Typography>
          <Typography variant="subtitle1" mb='0.35vh'>See the list of all open jobs right now</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '0.69vw' }}>
          <AssignButton variant="outlined" onClick={handleOpenFilterDialog}>Filter & Short</AssignButton>
          {/* <Button variant="contained" color="primary" onClick={() => setPopupOpen(true)}>Assign Task</Button> */}
        </Box>
        <FilterCandidates open={dialogOpenFilter} onClose={handleCloseFilterDialog} onApply={handleApply} />
      </Box>

      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" sx={{ padding: '0.14vh 1.66vw', whiteSpace: 'nowrap', textAlign: 'start' }}>
                <Checkbox checked={selectAll} onChange={handleSelectAll} />
              </TableCell>
              <TableCell sx={{ padding: '0.14vh 1.66vw', whiteSpace: 'nowrap', textAlign: 'start' }}><Typography variant='body3'>Candidate Name</Typography></TableCell>
              <TableCell sx={{ padding: '0.14vh 1.66vw', whiteSpace: 'nowrap', textAlign: 'start' }}><Typography variant='body3'>Position</Typography></TableCell>
              <TableCell sx={{ padding: '0.14vh 1.66vw', whiteSpace: 'nowrap', textAlign: 'start' }}><Typography variant='body3'>Eko Matching</Typography></TableCell>
              <TableCell sx={{ padding: '0.14vh 1.66vw', whiteSpace: 'nowrap', textAlign: 'start' }}><Typography variant='body3'>Function</Typography></TableCell>
              <TableCell sx={{ padding: '0.14vh 1.66vw', whiteSpace: 'nowrap', textAlign: 'start' }}><Typography variant='body3'>Recruiter</Typography></TableCell>
              <TableCell sx={{ padding: '0.14vh 1.66vw', whiteSpace: 'nowrap', textAlign: 'start' }}><Typography variant='body3'>Notes</Typography></TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {loading?(
              <TableRow>
              <TableCell
                colSpan={7} // This should span the number of columns you have in the table (adjust this if needed)
                sx={{
                  textAlign: 'center', // Horizontally center the text
                  fontWeight: 'bold',
                  padding: '20px', // Adjust padding for a better look
                  verticalAlign: 'middle', // Vertically center the text
                  color: theme.palette.text.secondary, // Optional: Set color for the message text
                }}
              >
                <CircularProgress disableShrink />
              </TableCell>
            </TableRow>
            ):filteredData.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={7} // This should span the number of columns you have in the table (adjust this if needed)
                  sx={{
                    textAlign: 'center', // Horizontally center the text
                    fontWeight: 'bold',
                    padding: '20px', // Adjust padding for a better look
                    verticalAlign: 'middle', // Vertically center the text
                    color: theme.palette.text.secondary, // Optional: Set color for the message text
                  }}
                >
                  No candidates data available
                </TableCell>
              </TableRow>
            ) : (filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((candidate, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor: index % 2 === 0 ? theme.palette.primary.light : 'inherit',
                  height: '4.86vh',
                }}
              >
                <TableCell padding="checkbox" sx={{ padding: '1.11vh 1.66vw', whiteSpace: 'nowrap' }}>
                  <Checkbox checked={selected.has(candidate.CandidateNum)} onChange={() => handleSelect(candidate.CandidateNum)} />
                </TableCell>
                <TableCell sx={{ padding: '1.11vh 1.66vw', whiteSpace: 'nowrap' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <UserAvatar>{candidate.CandidateName[0]}</UserAvatar>
                    <Typography variant='body2'>   {candidate.CandidateName}</Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ fontSize: '12px', padding: '1.6vh 2.4vw', whiteSpace: 'nowrap' }}>{candidate.CandidatePosition}</TableCell>
                <TableCell sx={{ fontSize: '12px', padding: '1.6vh 2.4vw', whiteSpace: 'nowrap' }}><Typography
                  variant="body2"
                  fontWeight='bold'
                >
                  {`${Math.round(candidate.MatchedPct || 0)}%`}

                </Typography>
                  {candidate.MatchedPct < 50 ? <Typography color="error">Unmatched</Typography> : <Typography color="success">Matched</Typography>}

                </TableCell>
                <TableCell sx={{ fontSize: '12px', padding: '1.6vh 2.4vw', whiteSpace: 'nowrap' }}>{candidate.CandidateFunction}</TableCell>
                <TableCell sx={{ fontSize: '12px', padding: '1.6vh 2.4vw', whiteSpace: 'nowrap' }}>{candidate.CandidateRecruiter}</TableCell>
                <TableCell sx={{ fontSize: '12px', padding: '1.6vh 2.4vw', whiteSpace: 'nowrap' }}>
                  <Button onClick={() => openNotesPopup(candidate.CandidateNum, candidate.CandidateStatus, candidate.CandidateRecruiter, candidate.CandidateName, candidate.MapID)}>
                    {/* {candidate.notes && candidate.notes.length > 0 ? 'View Notes' : 'Add a Note'} */}
                    View Notes
                  </Button>
                </TableCell>
              </TableRow>
            )))}
            { }
          </TableBody>
        </Table>
        <Box p='0.69vw'>
          <Pagination
            totalRows={filteredData.length}
            rowsPerPage={rowsPerPage}
            currentPage={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Box>
      </TableWrapper>

      {/* Assign Task Popup */}
      <AssignTaskPopup open={popupOpen} onClose={() => setPopupOpen(false)} />

      {/* Notes Popup */}
      <NotesPopup
        open={notesPopupOpen}
        onClose={() => setNotesPopupOpen(false)}
        candidateMapId={candidateMapID}
        userStatus={candidateStatus} // Pass the user status
        candidateNotes={filteredNotes}
        handleSave={handleSaveNote}
        candidateName={candidateName}
      />
    </Box>
  );
};

export default AssignTask;
