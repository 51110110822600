import React from 'react';
import { useState, useEffect } from 'react';
import {

  Typography,
  IconButton,
  Box,
} from '@mui/material';
import { theme } from '../../theme';
import '@fortawesome/fontawesome-free/css/all.min.css';
import CustomBreadcrumbs from './BreadCrumbs';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useParams, useNavigate } from 'react-router-dom';
import APIBaseURL from '../../AppConfig/AppConfig.json'
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import AvatarImage from './AvatarImage';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ProfileNavBarPopup from '../Pages/Profile/ProfileNavBarPopUp'
import ProfileEditPanel from '../Pages/Profile/ProfileEditPanel';

interface JwtPayload {
  UserNum?: number;
}
interface data {
  UserName: string;
  UserID: string;
  EmailID: string;
  UserNum: number;
}


const TopNav: React.FC = () => {
  const { tenant } = useParams<{ tenant: string }>();
  const [userData, setUserData] = useState<data>()
  const [error, setError] = useState('')
  const baseURL = window.location.origin.endsWith('/')
    ? window.location.origin
    : `${window.location.origin}/`;
  const navigate = useNavigate()
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isProfileEditVisible, setProfileEditVisible] = useState(false);
  const [updatedImage, setUpdatedImage] = useState<string | null>(null)

  const handlePopupClose = () => {
    setPopupOpen(false);
    setProfileEditVisible(false);
  };

  const handleTogglePopup = () => {
    setPopupOpen((prev) => !prev);
  };

  const handleProfileEditToggle = () => {
    setProfileEditVisible((prev) => !prev);
    setPopupOpen(false);
  };

  const updateUserData = (newData: data) => {
    setUserData(newData);
  };

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      setError('User not authenticated');
      return;
    }

    const { UserNum } = jwtDecode<JwtPayload>(token);
    if (!UserNum) {
      setError('Invalid token');
      return;
    }
    const fetchData = async () => {
      try {
        const response = await axios.get(`${APIBaseURL.apiBaseUrl}/user/get_user/${UserNum}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Tenant': tenant,
            'X-App': baseURL,
          },
        });
        setUserData(response.data);
      } catch (err) {
        setError('Failed to fetch user data.');
      }
    };

    fetchData();
  }, [tenant, baseURL]);

  const handleLogout = () => {
    localStorage.removeItem('access_token')
    navigate(`/${tenant}/SignIn`)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        // maxWidth: '80vw', // Adjusted to match parent width
        justifyContent: 'space-between',
        p: '0.5vw 2vw 2.5vw 2vw',
        // bgcolor:'black',
        height: '5vh',
        color: theme.palette.primary.main,
        // '& .MuiBox-root': {
        //   px: 4,
        // },
      }}
    >
      {/* Left Side: Logo and Company Name */}
      <Box sx={{ padding: '0 2vw 0 0' }}>
        <CustomBreadcrumbs />
      </Box>


      {/* Right Side: Menu Items */}
      <Box sx={{ display: 'flex', alignItems: 'center', color: theme.palette.text.secondary, pt: '3vh' }}>
        <IconButton color="inherit" aria-label="Help" sx={{ px: '0.8vw',gap:'0.3vw' }}>
          <i className="fa-regular fa-circle-question fa-xs" />
          <Typography color="textSecondary" fontSize="1.6vh" fontWeight={600}>
            Help
          </Typography>
        </IconButton>
        <IconButton color="inherit" aria-label="settings" sx={{p:'0.8vw',py:'1vw'}}>
          <i className="fa-solid fa-gear fa-xs" />
        </IconButton>
        <IconButton color="inherit" aria-label="notifications" sx={{p:'0.8vw',py:'1vw',mr:'0.5vw'}}>
          <i className="fa-regular fa-bell fa-xs"></i>
        </IconButton>

        <Box sx={{ position: 'relative' }}>

          <Box onClick={handleTogglePopup} sx={{ cursor: 'pointer' }}>
            {updatedImage ? (
              <AvatarImage imageUrl={updatedImage} height="38px" width="38px" />
            ) : userData?.UserNum ? (
              <AvatarImage imageUrl={`${APIBaseURL.apiBaseUrl}/user/get_user_image/${userData.UserNum}`} height="38px" width="38px" />
            ) : (
              <AccountCircleOutlinedIcon sx={{ fontSize: '38px' }} />
            )}
          </Box>

          {isPopupOpen && (
            <ProfileNavBarPopup
              name={userData?.UserName}
              email={userData?.EmailID}
              open={isPopupOpen}
              onClose={handlePopupClose}
              onProfileEditClick={handleProfileEditToggle} // Pass the handler here
              handleLogout={handleLogout}
            />
          )}
        </Box>
      </Box>

      {/* Conditionally Render ProfileEditPanel */}
      {isProfileEditVisible && (
        <ProfileEditPanel
          onClose={handlePopupClose}
          userData={userData}
          updateUserData={updateUserData}
          setUpdatedImage={setUpdatedImage}
        /> // Pass the onClose prop properly
      )}
    </Box>
  );
};

export default TopNav;

